import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
// const GET_AGENT_URL = `${API_URL}/agent/actives`
const PROJECT_URL = `${API_URL}/project`
const CHECKLIST_URL = `${API_URL}/checklist`
const RESPONSE_URL = `${API_URL}/ChecklistResponse`

export async function getProject(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PROJECT_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postChecklist(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${CHECKLIST_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postDuplicateChecklist(id: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${CHECKLIST_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getChecklist(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${CHECKLIST_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getChecklistbyId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${CHECKLIST_URL}/get/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getChecklistbyid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${CHECKLIST_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function updateChecklist(data: any, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${CHECKLIST_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteChecklist(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${CHECKLIST_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postResponse(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(
      `${RESPONSE_URL}/${data.checklistId}/response`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}
