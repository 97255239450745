import {createSlice} from '@reduxjs/toolkit'

const initialSlice = {
  parkingData: [],
  loading: true,
}

const parkingReportSlice = createSlice({
  name: 'parkingReport',
  initialState: initialSlice,
  reducers: {
    setParkingData(state, action) {
      state.parkingData = action.payload
      state.loading = false
    },
  },
})

export const {setParkingData} = parkingReportSlice.actions
export default parkingReportSlice.reducer
