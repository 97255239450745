import React, {useContext, useEffect, useState} from 'react'
import {getGeofencingbySurveyor, postGeoFenceLog} from '../_requests'

const TractLiveData: React.FC = () => {
  const surveyor = JSON.parse(localStorage.getItem('kt-auth-react-v')).fullName
  const [geoFences, setGeoFences] = useState([])
  const [checkIn, setCheckIn] = useState<boolean>(
    localStorage.getItem('checkIn') === 'true' ? false : true
  )
  const [checkOut, setCheckOut] = useState<boolean>(
    localStorage.getItem('checkOut') === 'true' ? true : false
  )
  const [currGeoFence, setCurrGeoFence] = useState<number>(0)

  useEffect(() => {
    const fetchData = async () => {
      const response = await getGeofencingbySurveyor(surveyor)
      setGeoFences(response)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const check = setInterval(() => {
      var lat, lng
      if ('geolocation' in navigator) {
        // Get the user's current position
        navigator.geolocation.getCurrentPosition(async function (position) {
          // Retrieve latitude and longitude
          lat = position.coords.latitude
          lng = position.coords.longitude
          if (checkOut) {
            const distance = google.maps.geometry.spherical.computeDistanceBetween(
              new google.maps.LatLng(lat, lng),
              new google.maps.LatLng(geoFences[currGeoFence].lat, geoFences[currGeoFence].lng)
            )
            if (distance > 500) {
              const data = {
                geoFenceId: geoFences[currGeoFence]._id,
                surveyor: surveyor,
                lat: lat,
                lng: lng,
                checkInOut: false,
              }
              const res = await postGeoFenceLog(data)
              if (res) {
                setCheckOut(false)
              }
            }
          } else {
            geoFences.every(async (elem, index) => {
              const distance = google.maps.geometry.spherical.computeDistanceBetween(
                new google.maps.LatLng(lat, lng),
                new google.maps.LatLng(elem.lat, elem.lng)
              )
              if (distance <= 500 && checkIn) {
                const data = {
                  geoFenceId: elem._id,
                  surveyor: surveyor,
                  lat: lat,
                  lng: lng,
                  checkInOut: true,
                }
                const res = await postGeoFenceLog(data)
                if (res) {
                  localStorage.setItem('checkIn', JSON.stringify('true'))
                  localStorage.setItem('checkOut', JSON.stringify('true'))
                  setCheckIn(false)
                  setCurrGeoFence(index)
                  setCheckOut(true)
                }
              }
            })
          }
        })
      }
    }, 5000)

    return () => clearInterval(check)
  }, [])

  return <></>
}

export default TractLiveData
