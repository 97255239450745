import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {getReportCount} from '../_requests'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store'
import { setReportCount } from '../../../redux/report/reportCountSlice'

const ReportList = () => {
  const dispatch = useDispatch()
  const reportCount = useSelector((state:RootState) => state.report.reportCount.countData)
  const loading = useSelector((state:RootState) => state.report.reportCount.loading)
  const options = [
    {value: 'interview', label: 'Interview'},
    {value: 'checklist', label: 'Check List'},
    {value: 'parking', label: 'Parking Occupancy'},
    {value: 'geofencing', label: 'Geo Fencing'},
    {value: 'journeytime', label: 'Journey Time'},
    {value: 'coordinate', label: 'Co-ordinate'},
  ]

  const fetchData = async () => {
    const res = await getReportCount()
    // setCountReport(res)
    dispatch(setReportCount(res))
  }

  useEffect(() => {
    if (loading) fetchData()
  }, [])

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Report List</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-50px ps-4 rounded-start'>No.</th>
                <th className='min-w-300px'>Name</th>
                <th className='min-w-200px'>Count</th>
                <th className='text-end rounded-end'></th>
              </tr>
            </thead>
            <tbody>
              {options.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <p className='font-semibold ps-4'>{index + 1}</p>
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: '/reports/reportList',
                          search: `?value=${data.value}`, // Pass option.value as a query parameter
                        }}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {data.label}
                      </Link>
                    </td>
                    <td className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'>
                      <p>{reportCount ? reportCount[data.value] : 0}</p>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
    </div>
  )
}

export default ReportList
