import React from 'react'
import {Route, Routes} from 'react-router-dom'
import CoordinatePage from './components/CoordinatePage'
import CoordinateForm from './components/CoordinateForm'
import CoordinateEditForm from './components/CoordinateEditForm'
import CoordinateDetails from './components/CoordinateDetails'

const Coordinate: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<CoordinatePage />} />
      <Route path='/create-coordinate' element={<CoordinateForm />} />
      <Route path='/:id' element={<CoordinateDetails />} />
      <Route path='edit-coordinate/:id' element={<CoordinateEditForm />} />
    </Routes>
  )
}

export default Coordinate
