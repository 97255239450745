import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {getProject, postParking} from '../_requests'
// import loadScript from 'load-script'
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete'
import {KTIcon} from '../../../../_metronic/helpers'
import {GoogleMap, InfoWindow, Marker} from '@react-google-maps/api'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setProjects} from '../../../redux/project/projectSlice'

const ParkingForm: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const projectList = useSelector((state: RootState) => state.project.projects)
  const [surveyorData, setSurveyorData] = useState([])
  const [surveyorList, setSurveyorList] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [coordinates, setCoordinates] = useState([])
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [markerLabel, setMarkerLabel] = useState([])

  const [parkingData, setParkingData] = useState({
    parkingName: '',
    project: '',
    surveyors: [],
  })
  const [response, setResponse] = useState({
    group: '',
    buildingName: '',
    floor: '',
    zone: '',
    supply: 0,
  })
  const [locationData, setLocationData] = useState({
    address: '',
    lat: '',
    lng: '',
  })
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [bool, setbool] = useState(false)
  const [locationValue, setLocationValue] = useState('')
  const [locationName, setLocationName] = useState('')
  const [center, setCenter] = useState({
    lat: 25.276987,
    lng: 55.296249,
  })
  const [timeInterval, setTimeInterval] = useState('')
  const [peakHours, setPeakHours] = useState({
    am: false,
    pm: false,
    lt: false,
  })
  const [startTimes, setStartTimes] = useState({
    am: '',
    pm: '',
    lt: '',
  })
  const [endTimes, setEndTimes] = useState({
    am: '',
    pm: '',
    lt: '',
  })

  // useEffect(() => {
  //   const loadGoogleScript = async () => {
  //     try {
  //       await new Promise<void>((resolve, reject) => {
  //         loadScript(
  //           `https://maps.googleapis.com/maps/api/js?key=AIzaSyA7grj1MqTFqXCMFr_iRHTrAtZSCU3opDQ&libraries=places`,
  //           (error) => {
  //             if (error) {
  //               reject(error)
  //             } else {
  //               resolve()
  //             }
  //           }
  //         )
  //       })
  //       setScriptLoaded(true)
  //     } catch (error) {
  //       console.error('Error loading Google Maps script', error)
  //     }
  //   }

  //   loadGoogleScript()
  // }, [])

  useEffect(() => {
    const getlist = async () => {
      const res = await getProject()
      const data = res.map((item, index) => {
        return item.agent
      })
      setSurveyorData(data)
      dispatch(setProjects(res))
    }
    if (projectList.length === 0) {
      getlist()
    } else {
      const data = projectList.map((item, index) => {
        return item.agent
      })
      setSurveyorData(data)
    }
  }, [])
  function convertToUTC(timeString: string) {
    if (timeString) {
      const [hours, minutes] = timeString.split(':').map(Number)
      const localDate = new Date()
      localDate.setHours(hours, minutes)
      return localDate.toISOString()
    } else {
      return '' // Return an empty string if the timeString is empty
    }
  }

  console.log(convertToUTC(startTimes.am))
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const peakHoursData = {
      AM: peakHours.am
        ? {
            startTime: convertToUTC(startTimes.am),
            endTime: convertToUTC(endTimes.am),
          }
        : null,
      PM: peakHours.pm
        ? {
            startTime: convertToUTC(startTimes.pm),
            endTime: convertToUTC(endTimes.pm),
          }
        : null,
      LT: peakHours.lt
        ? {
            startTime: convertToUTC(startTimes.lt),
            endTime: convertToUTC(endTimes.lt),
          }
        : null,
    }

    const peakHoursToSend = Object.fromEntries(
      Object.entries(peakHoursData).filter(([_, value]) => value !== null)
    )

    const data = {
      parkingName: parkingData.parkingName,
      project: parkingData.project,
      surveyors: parkingData.surveyors,
      group: response.group,
      buildingName: response.buildingName,
      floor: response.floor,
      zone: response.zone,
      supply: response.supply,
      address: locationData.address,
      lat: locationData.lat,
      lng: locationData.lng,
      timeInterval: timeInterval,
      peakHours: peakHoursToSend,
    }
    console.log(data)
    const res = await postParking(data)
    if (res) {
      navigate('/parking')
    }
  }

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address)
      const latLng = await getLatLng(results[0])
      setLocationName(results[0].formatted_address)
      setbool(true)
      setLocationData((prev) => {
        return {...prev, address: results[0].formatted_address, lat: latLng.lat, lng: latLng.lng}
      })
    } catch (error) {
      console.error('Error selecting location', error)
    }
  }

  if (!scriptLoaded) {
    return <div>Loading...</div>
  }
  const closeModal = () => {
    setShowModal(false)
  }
  const saveLocation = () => {
    console.log('saved')
    setLocationData({
      address: 'custom location',
      lat: coordinates[0]?.lat,
      lng: coordinates[0]?.lng,
    })
    setShowModal(false)
  }

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  }

  console.log(showModal)
  const handleMapClick = (event) => {
    setCoordinates((prev) => {
      return [
        {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
      ]
    })
    setMarkerLabel((prev) => {
      return ['']
    })
  }
  console.log(coordinates[0])
  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <h3 className='mb-6 text-lg font-semibold'>Create New Parking Occupancy</h3>
        <div className='w-full rounded-lg bg-white p-4'>
          <form id='kt_modal_add_user_form' className='form' onSubmit={handleFormSubmit}>
            <h3 className='mb-6 text-base font-semibold'>Parking Occupancy Name</h3>
            <input
              type='text'
              name='question'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={parkingData.parkingName}
              required
              onChange={(e) => {
                setParkingData((prev) => ({...prev, parkingName: e.target.value}))
              }}
            />
            <h3 className='my-6 text-base font-semibold'>Project Name</h3>
            {projectList.map((data, index) => {
              return (
                <div className='d-flex fv-row my-1' key={data._id}>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      type='radio'
                      name='project_name'
                      value={data._id}
                      id={`projectCheck${data._id}`}
                      className='form-check-input me-3'
                      required
                      onChange={() => {
                        setSurveyorList(index)
                        setParkingData((prev) => ({...prev, project: data._id}))
                      }}
                    />
                    <label htmlFor={`projectCheck${data._id}`} className='form-check-label'>
                      {data.name}
                    </label>
                  </div>
                </div>
              )
            })}
            <h3 className='my-6 text-base font-semibold'>Surveyors</h3>
            {surveyorData[surveyorList]?.map((data, index) => {
              return (
                <div className='d-flex fv-row my-1' key={index}>
                  <input
                    type='checkbox'
                    name='project_surveyor'
                    id={`surveyorCheck${data._id}`}
                    className='form-check-input me-3'
                    value={data.fullName}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setParkingData((prev) => ({
                          ...prev,
                          surveyors: [...prev.surveyors, e.target.value],
                        }))
                      } else {
                        setParkingData((prev) => ({
                          ...prev,
                          surveyors: prev.surveyors.filter((item) => item !== e.target.value),
                        }))
                      }
                    }}
                  />
                  <label htmlFor={`surveyorCheck${data._id}`} className='form-check-label'>
                    {data.fullName}
                  </label>
                </div>
              )
            })}
            <h3 className='my-6 text-base font-semibold'>Group</h3>
            <input
              type='text'
              name='group'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={response.group}
              required
              onChange={(e) => {
                setResponse((prev) => ({...prev, group: e.target.value}))
              }}
            />
            <h3 className='my-6 text-base font-semibold'>Name of the Building</h3>
            <input
              type='text'
              name='buildingName'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={response.buildingName}
              required
              onChange={(e) => {
                setResponse((prev) => ({...prev, buildingName: e.target.value}))
              }}
            />
            <h3 className='my-6 text-base font-semibold'>Parking floor</h3>
            <input
              type='text'
              name='floor'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={response.floor}
              required
              onChange={(e) => {
                setResponse((prev) => ({...prev, floor: e.target.value}))
              }}
            />
            <h3 className='my-6 text-base font-semibold'>Parking zone</h3>
            <input
              type='text'
              name='zone'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={response.zone}
              required
              onChange={(e) => {
                setResponse((prev) => ({...prev, zone: e.target.value}))
              }}
            />
            <h3 className='my-6 text-base font-semibold'>Parking supply</h3>
            <input
              type='number'
              name='supply'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={response.supply}
              required
              min={'0'}
              onChange={(e) => {
                setResponse((prev) => ({...prev, supply: Number(e.target.value)}))
              }}
            />

            <div className='fv-row mb-10'>
              <h3 className='my-6 text-base font-semibold'>Building Location</h3>

              {bool && (
                <input
                  className='form-control form-control-lg form-control-solid'
                  value={locationName}
                  onClick={() => setbool(false)}
                  readOnly
                />
              )}

              {!bool && (
                <PlacesAutocomplete
                  value={locationValue}
                  onChange={(value) => setLocationValue(value)}
                  onSelect={handleSelect}
                >
                  {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: 'Search Places...',
                          className: 'location-search-input',
                        })}
                        className='form-control form-control-lg form-control-solid'
                        value={locationValue}
                      />
                      <div className='autocomplete-dropdown-container'>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item'
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {className})}
                              key={suggestion.placeId}
                              className=' cursor-pointer form-control form-control-lg form-control-solid'
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
            </div>
            <div>
              or select from
              <span
                style={{color: 'blue', cursor: 'pointer'}} // Adding cursor pointer to indicate clickability
                onClick={() => setShowModal(true)}
              >
                {' '}
                Map <KTIcon iconName='arrow-up-right' className='text-primary' />
              </span>
            </div>

            <h3 className='my-6 text-base font-semibold'>Latitude</h3>
            <input
              type='text'
              name='lat'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={locationData.lat}
              required
              onChange={(e) => {
                setLocationData((prev) => ({...prev, lat: e.target.value}))
              }}
            />

            <h3 className='my-6 text-base font-semibold'>Longitude</h3>
            <input
              type='text'
              name='lng'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={locationData.lng}
              required
              onChange={(e) => {
                setLocationData((prev) => ({...prev, lng: e.target.value}))
              }}
            />
            <h3 className='my-6 text-base font-semibold'>Time Interval</h3>

            <select
              className='form-control form-control-solid mb-3 mb-lg-0'
              value={timeInterval}
              onChange={(e) => setTimeInterval(e.target.value)}
            >
              <option value=''>Select Time Interval</option>
              <option value='15'>15 mins</option>
              <option value='30'>30 mins</option>
              <option value='60'>60 mins</option>
            </select>

            <h3 className='my-6 text-base font-semibold'>Peak Hours</h3>
            <div className='form-check mb-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id='amCheckbox'
                checked={peakHours.am}
                onChange={(e) => {
                  setPeakHours((prev) => ({...prev, am: e.target.checked}))
                  if (!e.target.checked) {
                    setStartTimes((prev) => ({...prev, am: ''}))
                    setEndTimes((prev) => ({...prev, am: ''}))
                  }
                }}
              />
              <h3 className='my-6 text-base font-semibold'>AM</h3>
              {peakHours.am && (
                <div className='form-group mt-2'>
                  <h3 className='my-6 text-base font-semibold'>Start Time</h3>
                  <input
                    className='form-control form-control-solid mb-3 mb-lg-0 w-50'
                    id='startTimeInput'
                    type='time'
                    value={startTimes.am}
                    onChange={(e) => setStartTimes((prev) => ({...prev, am: e.target.value}))}
                  />
                  <h3 className='my-6 text-base font-semibold'>End Time</h3>
                  <input
                    className='form-control form-control-solid mb-3 mb-lg-0 w-50'
                    id='endTimeInput'
                    type='time'
                    value={endTimes.am}
                    onChange={(e) => setEndTimes((prev) => ({...prev, am: e.target.value}))}
                  />
                </div>
              )}
            </div>

            <div className='form-check mb-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id='pmCheckbox'
                checked={peakHours.pm}
                onChange={(e) => {
                  setPeakHours((prev) => ({...prev, pm: e.target.checked}))
                  if (!e.target.checked) {
                    setStartTimes((prev) => ({...prev, pm: ''}))
                    setEndTimes((prev) => ({...prev, pm: ''}))
                  }
                }}
              />
              <h3 className='my-6 text-base font-semibold'>PM</h3>
              {peakHours.pm && (
                <div className='form-group mt-2'>
                  <h3 className='my-6 text-base font-semibold'>Start Time</h3>

                  <input
                    className='form-control form-control-solid mb-3 mb-lg-0 w-50'
                    type='time'
                    value={startTimes.pm}
                    onChange={(e) => setStartTimes((prev) => ({...prev, pm: e.target.value}))}
                  />
                  <h3 className='my-6 text-base font-semibold'>End Time</h3>

                  <input
                    className='form-control form-control-solid mb-3 mb-lg-0 w-50'
                    type='time'
                    value={endTimes.pm}
                    onChange={(e) => setEndTimes((prev) => ({...prev, pm: e.target.value}))}
                  />
                </div>
              )}
            </div>
            <div className='form-check mb-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id='ltCheckbox'
                checked={peakHours.lt}
                onChange={(e) => {
                  setPeakHours((prev) => ({...prev, lt: e.target.checked}))
                  if (!e.target.checked) {
                    setStartTimes((prev) => ({...prev, lt: ''}))
                    setEndTimes((prev) => ({...prev, lt: ''}))
                  }
                }}
              />
              <h3 className='my-6 text-base font-semibold'>LT</h3>
              {peakHours.lt && (
                <div className='form-group mt-2'>
                  <h3 className='my-6 text-base font-semibold'>Start Time</h3>

                  <input
                    className='form-control form-control-solid mb-3 mb-lg-0 w-50'
                    type='time'
                    value={startTimes.lt}
                    onChange={(e) => setStartTimes((prev) => ({...prev, lt: e.target.value}))}
                  />
                  <h3 className='my-6 text-base font-semibold'>End Time</h3>

                  <input
                    className='form-control form-control-solid mb-3 mb-lg-0 w-50'
                    type='time'
                    value={endTimes.lt}
                    onChange={(e) => setEndTimes((prev) => ({...prev, lt: e.target.value}))}
                  />
                </div>
              )}
            </div>

            <div className='mx-auto my-6 md:ml-8'>
              <button className='btn btn-primary' type='submit'>
                Create Parking Occupancy
              </button>
            </div>
          </form>
        </div>
      </div>
      {showModal && (
        <div className='modal' style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
          <div className='modal-dialog modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Choose your Location</h5>
                <button type='button' className='btn-close' onClick={closeModal}></button>
              </div>
              <div className='modal-body'>
                {/* Modal body content */}
                <div className='card-body py-4'>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={14}
                    onClick={(e) => {
                      handleMapClick(e)
                      setSelectedMarker(null)
                    }}
                  >
                    {/* You can add markers, polylines, etc. here if needed */}
                    {coordinates.map((item, index) => {
                      return (
                        <Marker
                          position={item}
                          key={index}
                          onClick={() => setSelectedMarker(index)}
                          label={markerLabel[index]}
                        >
                          {selectedMarker === index && (
                            <InfoWindow
                              onCloseClick={() => setSelectedMarker(null)}
                              position={item}
                            >
                              <div>
                                <h3>Marker {index + 1}</h3>
                                <input
                                  type='text'
                                  value={markerLabel[index]}
                                  placeholder='Add label'
                                  className='form-control form-control-solid mb-3 mb-lg-0'
                                  onChange={(e) => {
                                    setMarkerLabel((prev) => {
                                      const newMarkerLabel = [...prev]
                                      newMarkerLabel[index] = e.target.value
                                      return newMarkerLabel
                                    })
                                  }}
                                />
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      )
                    })}
                  </GoogleMap>
                </div>{' '}
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-primary' onClick={saveLocation}>
                  Save
                </button>
                <button type='button' className='btn btn-danger' onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default ParkingForm
