import TextInput from './FormElements/TextInput'
import {useEffect, useState} from 'react'
import MultipleChoiceInput from './FormElements/MultipleChoiceInput'
import CheckBoxesInput from './FormElements/CheckBoxesInput'
import DropdownInput from './FormElements/DropdownInput'
import ToggleButton from './FormElements/ToggleButton'
import {KTIcon} from '../../../../_metronic/helpers'
import ToggleButton2 from './FormElements/ToggleButton2'
import {getChecklistbyid, getProject, updateChecklist} from '../_requets'
import {useNavigate, useParams} from 'react-router-dom'

const ChecklistEditForm = () => {
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const res = await getChecklistbyid(params.id)
      setFormData(res.questions)
      setChecklistData({
        CheckListName: res.CheckListName,
        project: res.project,
        surveyors: res.surveyors,
      })
      console.log(res)
    }
    fetchData()
  }, [params.id])

  const [formData, setFormData] = useState([
    {
      question: '',
      type: 'text',
      required: false,
      other: false,
      options: [''],
    },
  ])

  const [projectList, setProjectList] = useState([])
  const [surveyorList, setSurveyorList] = useState(0)
  const [checklistData, setChecklistData] = useState({
    CheckListName: '',
    project: '',
    surveyors: ['faisal', 'reez'],
  })

  useEffect(() => {
    const getlist = async () => {
      const res = await getProject()
      console.log(res)
      setProjectList(res)
    }
    getlist()
  }, [])

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = {
      questions: formData,
      ...checklistData,
    }
    const res = await updateChecklist(data, params.id)
    navigate('/checklist')
    console.log(res)
  }

  const handleRequiredButton = (index: number) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index] = {
        ...updatedFormData[index],
        required: !updatedFormData[index].required,
      }
      return updatedFormData
    })
  }

  const handleOtherInputButton = (index: number) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index] = {
        ...updatedFormData[index],
        other: !updatedFormData[index].other,
      }
      return updatedFormData
    })
  }

  const handleAddQuestion = () => {
    setFormData((prev) => [
      ...prev,
      {
        question: '',
        type: 'text',
        required: false,
        other: false,
        options: [''],
      },
    ])
  }

  const updateOptions = (index: number, optionIndex: number, newOption: string) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index] = {
        ...updatedFormData[index],
        options: updatedFormData[index].options.map((item, i) => {
          if (i === optionIndex) {
            return newOption
          }
          return item
        }),
      }
      return updatedFormData
    })
  }

  const AddNewOption = (index: number) => {
    setFormData((prev) => {
      const updatedForm = prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            options: [...item.options, ''],
          }
        }
        return item
      })
      return updatedForm
    })
  }

  const deleteOption = (index: number, optionIndex: number) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index] = {
        ...updatedFormData[index],
        options: updatedFormData[index].options.filter((_, i) => i !== optionIndex),
      }
      return updatedFormData
    })
  }

  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <h3 className='mb-6 text-lg font-semibold'>Create New CheckList</h3>
        <div className='w-full rounded-lg bg-white p-4'>
          <form id='kt_modal_add_user_form' className='form' noValidate onSubmit={handleFormSubmit}>
            <h3 className='mb-6 text-base font-semibold'>CheckList Name</h3>
            <input
              type='text'
              name='question'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={checklistData.CheckListName}
              onChange={(e) => {
                setChecklistData((prev) => ({...prev, CheckListName: e.target.value}))
              }}
            />
            <h3 className='my-6 text-base font-semibold'>Project Name</h3>
            {projectList.map((data, index) => {
              return (
                <div className='d-flex fv-row my-1' key={data._id}>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      type='radio'
                      name='project_name'
                      value={data._id}
                      id={`projectCheck${data._id}`}
                      className='form-check-input me-3'
                      onChange={() => {
                        setSurveyorList(index)
                        setChecklistData((prev) => ({...prev, project: data._id}))
                      }}
                    />
                    <label htmlFor={`projectCheck${data._id}`} className='form-check-label'>
                      {data.name}
                    </label>
                  </div>
                </div>
              )
            })}
            <h3 className='my-6 text-base font-semibold'>Surveyors</h3>
            {/* {projectList[surveyorList].surveyors.map((data, index) => {
              return (
                <div className='d-flex fv-row my-1' key={index}>
                  <input
                    type='checkbox'
                    name='project_surveyor'
                    id='faisal'
                    className='form-check-input me-3'
                  />
                  <label htmlFor='faisal' className='form-check-label'>
                    {data}
                  </label>
                </div>
              )
            })} */}
            <div className=''>
              {formData.map((data, index) => {
                return (
                  <div key={index}>
                    <h3 className='my-6 text-base font-semibold'>CheckList Questions</h3>
                    <div className='flex w-full items-center'>
                      <input
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0 flex-grow' // Add flex-grow to expand the input
                        placeholder='What is your question?'
                        value={data.question}
                        onChange={(e) => {
                          setFormData((prev) => {
                            const updatedFormData = prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  question: e.target.value,
                                }
                              }
                              return item
                            })
                            return updatedFormData
                          })
                        }}
                      />
                      <button
                        className='rounded-lg bg-red-300 p-2 md:ml-4'
                        type='button'
                        onClick={() =>
                          setFormData((prev) => prev.filter((_, idx) => idx !== index))
                        }
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </button>
                    </div>
                    <div className='mx-4 my-6'>
                      <h4 className='mb-4 text-base font-semibold'>Field Type</h4>
                      <select
                        name=''
                        id=''
                        className='form-select form-select-sm form-select-solid'
                        ata-control='select2'
                        data-placeholder='Latest'
                        data-hide-search='true'
                        onChange={(e) => {
                          setFormData((prev) => {
                            const updatedFormData = prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  type: e.target.value,
                                }
                              }
                              return item
                            })
                            return updatedFormData
                          })
                        }}
                      >
                        <option value='text'>Text</option>
                        <option value='checkbox'>Checkboxes</option>
                        <option value='multiple'>Multiple Choice</option>
                        <option value='dropdown'>Dropdown</option>
                      </select>
                    </div>
                    <div className=''>
                      {data.type === 'text' && <TextInput {...data} />}
                      {data.type === 'checkbox' && (
                        <CheckBoxesInput
                          options={data.options}
                          updateOptions={(newOption, optionIndex) =>
                            updateOptions(index, optionIndex, newOption)
                          }
                          AddNewOption={() => AddNewOption(index)}
                          deleteOption={(optionIndex) => deleteOption(index, optionIndex)}
                        />
                      )}
                      {data.type === 'multiple' && (
                        <MultipleChoiceInput
                          options={data.options}
                          updateOptions={(newOption, optionIndex) =>
                            updateOptions(index, optionIndex, newOption)
                          }
                          AddNewOption={() => AddNewOption(index)}
                          deleteOption={(optionIndex) => deleteOption(index, optionIndex)}
                        />
                      )}
                      {data.type === 'dropdown' && (
                        <DropdownInput
                          options={data.options}
                          updateOptions={(newOption, optionIndex) =>
                            updateOptions(index, optionIndex, newOption)
                          }
                          AddNewOption={() => AddNewOption(index)}
                          deleteOption={(optionIndex) => deleteOption(index, optionIndex)}
                        />
                      )}
                    </div>
                    <div className='flex gap-4'>
                      <ToggleButton
                        handleRequiredButton={() => handleRequiredButton(index)}
                        index={index}
                      />
                      {data.type !== 'text' && (
                        <ToggleButton2
                          handleOtherInputButton={() => handleOtherInputButton(index)}
                          index={index}
                        />
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='mx-auto my-6 flex items-center justify-center'>
              <button type='button' className='btn btn-secondary' onClick={handleAddQuestion}>
                Add Question
              </button>
            </div>
            <div className='mx-auto my-6 md:ml-8'>
              <button className='btn btn-primary' type='submit'>
                Edit CheckList
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ChecklistEditForm
