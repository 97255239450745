import {createSlice} from '@reduxjs/toolkit'

const initialSlice = {
  parkingData: [],
  loading: true,
}

const parkingSlice = createSlice({
  name: 'parking',
  initialState: initialSlice,
  reducers: {
    setParking(state, action) {
      state.parkingData = action.payload
      state.loading = false
    },
  },
})

export const {setParking} = parkingSlice.actions
export default parkingSlice.reducer
