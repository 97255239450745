import React from "react";
import { KTIcon } from "../../../../../_metronic/helpers";

interface CheckBoxexInputProps {
  options: string[];
  updateOptions: (newOption: string, optionIndex: number) => void;
  AddNewOption: () => void;
  deleteOption: (optionIndex: number) => void;
}

const CheckBoxesInput: React.FC<CheckBoxexInputProps> = ({
  options,
  updateOptions,
  AddNewOption,
  deleteOption,
}) => {
  const handleOptionsChange = (
    e: React.FormEvent<HTMLInputElement>,
    index: number,
  ) => {
    updateOptions(e.currentTarget.value, index);
  };

  const handleAddOption = () => {
    AddNewOption();
  };

  const handleDeleteOption = (optionIndex: number) => {
    if (options.length === 1) return;
    deleteOption(optionIndex);
  };
  return (
    <div className="my-4 ml-2">
      <div className="flex flex-col gap-2">
        {options.map((data, index) => {
          return (
            <div
  key={index}
  className="flex items-center gap-1 md:gap-2"
>
  <input
    type="checkbox"
    name=""
    id=""
    className="form-check-input me-3"
    disabled
  />
  <input
    type="text"
    placeholder={`Option ${index + 1}`}
    className="form-control form-control-solid mb-3 mb-lg-0 flex-grow" // Added flex-grow to expand the input
    value={data}
    onChange={(e) => {
      handleOptionsChange(e, index);
    }}
  />
  <button
    className="rounded-lg bg-red-300 p-2 md:ml-4"
    type="button"
    onClick={() => handleDeleteOption(index)}
  >
    <KTIcon iconName='trash' className='fs-3' />
  </button>
</div>

          );
        })}
        <div className="my-2 ml-8">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={handleAddOption}
          >
            Add Option
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckBoxesInput;
