import {configureStore} from '@reduxjs/toolkit'
import projectReducer from './project/projectSlice'
import reportReducer from './report/reportReducer'
import interviewReducer from './interview/interviewSlice'
import geofencingReducer from './geofencing/geofencingSlice'
import journeyTimeReducer from './journeyTime/journeyTimeSlice'
import coordinateReducer from './coordinate/coordinateSlice'
import checkListReducer from './checklist/checklistSlice'
import parkingReducer from './parking/parkingSlice'

export const store = configureStore({
  reducer: {
    project: projectReducer,
    report: reportReducer,
    interview: interviewReducer,
    geofencing: geofencingReducer,
    journeyTime: journeyTimeReducer,
    coordinate: coordinateReducer,
    checkList: checkListReducer,
    parking: parkingReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export default store
export type RootState = ReturnType<typeof store.getState>
