import React, {useEffect, useState} from 'react'
import {GoogleMap, Marker} from '@react-google-maps/api'
import {getChecklistGeoCoordinates} from '../_ChecklistRequest'

const containerStyle = {
  width: '100%',
  height: '500px',
}

const center = {
  lat: 19.1412074,
  lng: 72.863352,
}

const GeoMapChecklist: React.FC = () => {
  const [coordinates, setCoordinates] = useState([])

  useEffect(() => {
    const url = window.location.pathname
    const parts = url.split('/')
    const fetchData = async () => {
      const res = await getChecklistGeoCoordinates(parts[3])
      console.log(res[0])
      setCoordinates(res[0])
      console.log('coord', coordinates)
    }
    fetchData()
  }, [])

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map: {
    fitBounds: (arg0: google.maps.LatLngBounds) => void
  }) {
    const bounds = new window.google.maps.LatLngBounds(center)
    map.fitBounds(bounds)

    setMap(map)
  },
  [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return (
    <div className='my-4'>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {coordinates.map((item, index) => (
          <Marker position={item} key={index} />
        ))}
      </GoogleMap>
    </div>
  )
}

export default GeoMapChecklist
