import {createSlice} from '@reduxjs/toolkit'

const initialSlice = {
  interviewData: [],
  loading: true,
}

const interviewSlice = createSlice({
  name: 'interview',
  initialState: initialSlice,
  reducers: {
    setInterviewData(state, action) {
      state.interviewData = action.payload
      state.loading = false
    },
    deleteInterview(state, action) {
      state.interviewData = state.interviewData.filter((item) => item._id !== action.payload)
    },
    addInterview(state, action) {
      state.interviewData.push(action.payload)
    },
    editInterview(state, action) {
      const index = state.interviewData.findIndex((item) => item._id === action.payload._id)
      state.interviewData[index] = action.payload
    },
  },
})

export const {setInterviewData, editInterview, addInterview, deleteInterview} =
  interviewSlice.actions
export default interviewSlice.reducer
