import React, {useEffect, useState} from 'react'
import {getParkingFirstRecordbyId, getParkingbyid, postResponse} from '../_requests'
import {useNavigate, useParams} from 'react-router-dom'

const ParkingQuestionaire: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState({
    parkingName: '',
    group: '',
    buildingName: '',
    floor: '',
    zone: '',
    supply: 0,
    peakHours: {},
    timeInterval: 0,
  })
  const [projectName, setProjectName] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [response, setResponse] = useState({
    beforeTime: '',
    beforeOccupied: 0,
    timeInterval: '',
    parked: 0,
  })
  const [options, setOptions] = useState([])
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [interval, setInterval] = useState()
  const [timeOptions, setTimeOptions] = useState<any>([])
  const [initialTime, setInitialTime] = useState()
  const [lock, setLock] = useState(false)
  console.log(initialTime)

  console.log(timeOptions.humanReadableFormat)
  useEffect(() => {
    const fetchData = async () => {
      const res = await getParkingbyid(params.id)
      setOptions(Object.keys(res.peakHours))
      setInterval(res.timeInterval) // convert to minutes
      setData(res)
      setProjectName(res.project.name)
    }
    const fetchTime = async () => {
      const res = await getParkingFirstRecordbyId(params.id)
      setInitialTime(res)
    }
    fetchTime()
    fetchData()
  }, [])
  console.log(selectedOption)
  console.log(params.id)

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formatTime = (isoTimeString: string) => {
      const date = new Date(isoTimeString)
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const period = hours >= 12 ? 'PM' : 'AM'
      const formattedHours = (((hours + 11) % 12) + 1).toString().padStart(2, '0')
      const formattedMinutes = minutes.toString().padStart(2, '0')
      return `${formattedHours}:${formattedMinutes} ${period}`
    }

    const formattedBeforeTime = formatTime(response.beforeTime)
    const formattedTimeInterval = formatTime(response.timeInterval)

    const data = {
      parkingId: params.id,
      ...response,
      peakHour: selectedOption,
      beforeTime: formattedBeforeTime,
      timeInterval: formattedTimeInterval,
    }

    console.log(data)
    const res = await postResponse(data)
    if (res) {
      navigate('/parking')
    }
  }
  useEffect(() => {
    if (startTime && endTime && interval) {
      const timeOptionsArray = []
      let currentTime = new Date(startTime)
      const endTimeDate = new Date(endTime)
      const getFormattedTime = (date) => {
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const period = hours >= 12 ? 'PM' : 'AM'
        const formattedHours = (((hours + 11) % 12) + 1).toString().padStart(2, '0')
        const formattedMinutes = minutes.toString().padStart(2, '0')
        return `${formattedHours}:${formattedMinutes} ${period}`
      }

      while (currentTime <= endTimeDate) {
        const originalFormat = currentTime.toISOString()
        const humanReadableFormat = getFormattedTime(currentTime)
        timeOptionsArray.push({originalFormat, humanReadableFormat})
        currentTime.setMinutes(currentTime.getMinutes() + interval)
      }

      setTimeOptions(timeOptionsArray)
    }
  }, [startTime, endTime, interval])
  useEffect(() => {
    if (data && selectedOption) {
      const groupData = data?.peakHours[selectedOption]
      if (groupData) {
        setStartTime(groupData.startTime)
        setEndTime(groupData.endTime)
      }
    }
    if (initialTime && selectedOption) {
      const peakData: any = initialTime[selectedOption]
      if (peakData) {
        setResponse((prev) => ({...prev, beforeTime: peakData?.beforeTime}))
        setResponse((prev) => ({...prev, beforeOccupied: Number(peakData.beforeOccupied)}))
        setLock(true)
      } else {
        setResponse((prev) => ({...prev, beforeTime: ''}))
        setResponse((prev) => ({...prev, beforeOccupied: Number(0)}))
        setLock(false)
      }
    }
  }, [selectedOption, data])

  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <h3 className='mb-6 text-lg font-semibold capitalize'>{data.parkingName}</h3>
        <h4 className='mb-4 text-base font-medium capitalize'>{projectName}</h4>
        <div className='w-full rounded-lg bg-white text-base md:mx-4'>
          <div className='col-lg-12 p-4'>
            <div className='font-semibold'>
              <div className='my-2'>
                <p className=''>Group</p>
                <span className='text-primary ml-2'>{data.group}</span>
              </div>
              <div className='my-2'>
                <p className=''>Name of the Building</p>
                <span className='text-primary ml-2'>{data.buildingName}</span>
              </div>
              <div className='my-2'>
                <p className=''>Parking floor</p>
                <span className='text-primary ml-2'>{data.floor}</span>
              </div>
              <div className='my-2'>
                <p className=''>Parking zone</p>
                <span className='text-primary ml-2'>{data.zone}</span>
              </div>
              <div className='my-2'>
                <p className=''>Parking supply</p>
                <span className='text-primary ml-2'>{data.supply}</span>
              </div>
            </div>
          </div>
          <hr />
          <form id='kt_modal_add_user_form' className='form' onSubmit={handleFormSubmit}>
            <div className='flex flex-col justify-around gap-4 p-4 text-gray-800'>
              <p className=''>
                Select Peak Hours <span className='text-red-600'>*</span>
              </p>
              <select
                value={selectedOption}
                className='form-select form-select-sm form-select-solid mb-2'
                required
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value='' disabled>
                  Select Peak Hours{' '}
                </option>
                {options?.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <p className=''>
                Parking occupied time before the survey <span className='text-red-600'>*</span>
              </p>
              <select
                id='timeSelect'
                value={response.beforeTime}
                className='form-select form-select-sm form-select-solid mb-2'
                required
                disabled={lock}
                onChange={(e) => setResponse((prev) => ({...prev, beforeTime: e.target.value}))}
              >
                <option value='' disabled>
                  Select a time
                </option>
                {timeOptions?.map((option, index) => (
                  <option key={index} value={option.originalFormat}>
                    {option.humanReadableFormat}
                  </option>
                ))}{' '}
              </select>
              <p className=''>
                Parking occupied number before the survey <span className='text-red-600'>*</span>
              </p>
              <input
                type='text'
                className='form-control form-control-solid mb-3 mb-lg-0'
                placeholder='Parking occupied number before the survey'
                required
                min={'0'}
                disabled={lock}
                max={`${data.supply}`}
                value={response.beforeOccupied}
                onChange={(e) =>
                  setResponse((prev) => ({...prev, beforeOccupied: Number(e.target.value)}))
                }
              />
              <p className=''>
                Parking Occupied time interval <span className='text-red-600'>*</span>
              </p>
              <select
                id='timeSelect'
                value={response.timeInterval}
                className='form-select form-select-sm form-select-solid mb-2'
                required
                onChange={(e) => setResponse((prev) => ({...prev, timeInterval: e.target.value}))}
              >
                <option value='' disabled>
                  Select a time
                </option>
                {timeOptions?.map((option, index) => (
                  <option key={index} value={option.originalFormat}>
                    {option.humanReadableFormat}
                  </option>
                ))}{' '}
              </select>
              <p className=''>
                Number of cars Parked <span className='text-red-600'>*</span>
              </p>
              <input
                type='number'
                className='form-control form-control-solid mb-3 mb-lg-0'
                placeholder='Number of cars Parked'
                required
                min={'0'}
                max={`${data.supply}`}
                value={response.parked}
                onChange={(e) => setResponse((prev) => ({...prev, parked: Number(e.target.value)}))}
              />
              <p className=''>
                Parking occupancy percentage:{' '}
                <span className='text-primary ml-2'>
                  {response.parked ? (response.parked * 100) / data.supply : 0}%
                </span>
              </p>
            </div>
            <div className=''>
              <button type='submit' className='btn btn-primary upparcase my-4 mx-4'>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ParkingQuestionaire
