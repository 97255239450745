import React, {useEffect, useRef, useState} from 'react'
import {Pie, Bar, Doughnut} from 'react-chartjs-2'
import {useLocation, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {getInterviewByProjectGroup} from '../Core/_requets'

const InterviewGraph = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const value = searchParams.get('value')
  const {currentUser} = useAuth()
  const {id} = useParams()

  const [answers, setAnswers] = useState([])
  const [chartData, setChartData] = useState({})
  const printContainerRef = useRef(null)

  function toSentenceCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }
  const fetchData = async () => {
    try {
      const res = await getInterviewByProjectGroup(id, value)
      const extractedAnswers = res.map((obj) => obj.answers).flat()
      setAnswers(extractedAnswers)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  const barChartRef = useRef(null)
  const pieChartRef = useRef(null)

  useEffect(() => {
    if (barChartRef.current) {
      barChartRef.current.chartInstance.options.devicePixelRatio = window.devicePixelRatio
    }
    if (pieChartRef.current) {
      pieChartRef.current.chartInstance.options.devicePixelRatio = window.devicePixelRatio
    }
  }, [barChartRef, pieChartRef])

  useEffect(() => {
    const getReports = () => {
      const dataForChart = {}
      const dataForChart1 = {}

      answers.forEach((answer) => {
        answer.questions.forEach((question) => {
          if (
            question.type === 'checkbox' ||
            question.type === 'multiple' ||
            question.type === 'dropdown'
          ) {
            if (!dataForChart[question.question]) {
              dataForChart[question.question] = {}
              question.response.forEach((response) => {
                if (!dataForChart[question.question][response]) {
                  dataForChart[question.question][response] = 1
                } else {
                  dataForChart[question.question][response] += 1
                }
              })
            } else {
              question.response.forEach((response) => {
                if (!dataForChart[question.question][response]) {
                  dataForChart[question.question][response] = 1
                } else {
                  dataForChart[question.question][response] += 1
                }
              })
            }
          } else if (question.type === 'text') {
            if (!dataForChart1[question.question]) {
              dataForChart1[question.question] = {}
              question.response.forEach((response) => {
                const responseKey = toSentenceCase(response)
                if (!dataForChart1[question.question][responseKey]) {
                  dataForChart1[question.question][responseKey] = 1
                } else {
                  dataForChart1[question.question][responseKey] += 1
                }
              })
            } else {
              question.response.forEach((response) => {
                const responseKey = toSentenceCase(response)
                if (!dataForChart1[question.question][responseKey]) {
                  dataForChart1[question.question][responseKey] = 1
                } else {
                  dataForChart1[question.question][responseKey] += 1
                }
              })
            }
            const sortedResponses = Object.entries(dataForChart1[question.question]).sort(
              ([, countA], [, countB]) => Number(countB) - Number(countA)
            )

            const top5Responses = sortedResponses.slice(0, 5)
            console.log('tope5response', top5Responses)

            setChartData((prevChartData) => {
              return {
                ...prevChartData,
                [question.question]: Object.fromEntries(top5Responses),
              }
            })
          }
        })
      })

      setChartData((prev) => {
        return {
          ...prev,
          ...dataForChart,
        }
      })

      console.log(dataForChart)
    }
    console.log('chartData', chartData)

    getReports()
  }, [answers])

  const charts = () => {
    return Object.entries(chartData).map(([question, data]) => {
      const chartColors = ['#003B73', '#0074B7', '#60A3D9', '#BFD7ED', '#AAF1F5']
      const chartColorsb = ['#003B73', '#0074B7', '#050A30', '#60A3D9', '#5289B5', '#AAF1F5']
      const adjustedChartColors = chartColorsb.map((color) => `${color}BF`)

      const isMobile = window.innerWidth <= 768
      if (Object.keys(data).length > 4) {
        return (
          <div className='md:my-3 md:py-4 md:px-6 justify-center text-left h-fit '>
            <div
              key={question}
              className='flex-inline justify-between h-fit gap-7 md:my-1  bg-white shadow border rounded-xl transition-transform duration-300 transform hover:scale-105 hover:ring'
            >
              <h3 className='  text-white text-sm md:text-2xl py-4 px-4 font-semibold bg-gray-900 rounded-t-2xl'>
                <span className=' p-3 md:px-8 md:py-5 '>
                  {(question as string).charAt(0).toUpperCase() +
                    (question as string).slice(1).toLowerCase()}
                </span>
              </h3>
              <Bar
                ref={barChartRef}
                className='w-full md:mx-auto md:my-8 px-24 md:px-8 md:py-8'
                data={{
                  labels: Object.keys(data).sort(),
                  datasets: [
                    {
                      data: Object.values(data),
                      backgroundColor: chartColorsb,
                      borderColor: chartColors,
                      barThickness: isMobile ? 20 : 40,
                    },
                  ],
                }}
                options={{
                  indexAxis: isMobile ? 'y' : 'x',
                  responsive: true,
                  aspectRatio: isMobile ? 1 : 2,
                  maintainAspectRatio: true,
                  elements: {
                    bar: {
                      borderRadius: 14,
                      base: 3,
                      inflateAmount: 0.3,
                      backgroundColor: '#17C653',
                      borderWidth: 0.5,
                    },
                  },
                  plugins: {
                    legend: {
                      position: isMobile ? 'bottom' : 'top',
                      align: isMobile ? 'start' : 'center',
                      display: true,
                      fullSize: true,

                      labels: {
                        font: {
                          weight: 'semibold',
                          size: 14,
                        },
                        // Update the legend labels to include the count of responses
                        generateLabels: (chart) => {
                          const {data} = chart
                          if (data.labels.length && data.datasets.length) {
                            return data.labels.map((label, index) => {
                              const value = data.datasets[0].data[index]
                              const sentenceCaseLabel =
                                (label as string).charAt(0).toUpperCase() +
                                (label as string).slice(1).toLowerCase()
                              return {
                                text: `${sentenceCaseLabel} - ${value} `,

                                fillStyle: data.datasets[0].backgroundColor[index],
                                hidden: false,
                              }
                            })
                          }
                          return []
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      border: {
                        display: true,
                        dashOffset: 5,
                        dash: [10, 10],
                      },

                      grid: {
                        display: isMobile ? false : true,
                      },

                      position: 'bottom',

                      ticks: {
                        precision: 0,
                        color: 'gray',
                        font: {
                          weight: 'bold',
                        },
                      },
                    },
                    y: {
                      display: false,
                      border: {
                        display: true,
                        color: '#fff',
                        dashOffset: 30,
                        dash: [7, 7],
                      },
                      grid: {
                        display: isMobile ? true : false,
                      },
                      type: isMobile ? 'category' : 'linear',

                      ticks: {
                        color: 'gray-800',
                        font: {
                          weight: 'bold',
                        },
                      },
                      // position: "left",
                    },
                  },
                }}
              />
            </div>
          </div>
        )
      }
    })
  }
  const PieCharts: any = () => {
    return Object.entries(chartData).map(([question, data]) => {
      const chartColorsb = ['#003B73', '#0074B7', '#60A3D9', '#BFD7ED']
      const adjustedChartColors = chartColorsb.map((color) => `${color}BF`)
      // const legendCallback = (chart: Chart) => {
      //   const data = chart.data;
      //   if (data.labels.length && data.datasets.length) {
      //     const legendItems = data.labels.map((label, index) => {
      //       const value = data.datasets[0].data[index];
      //       const sentenceCaseLabel =
      //         (label as string).charAt(0).toUpperCase() + (label as string).slice(1).toLowerCase();
      //       return `<li><span style="background-color:${data.datasets[0].backgroundColor[index]}"></span>${sentenceCaseLabel} - ${value}</li>`;
      //     });

      //     return `<ul>${legendItems.join('')}</ul>`;
      //   }
      //   return '';
      // };

      const isMobile = window.innerWidth <= 768
      if (Object.keys(data).length <= 4) {
        return (
          <div className=' md:mx-7 md:my-3 text-left  md:items-center  md:justify-center '>
            <div
              key={question}
              className=' flex-inline my-1 bg-white shadow-sm border border-gray-200 rounded-xl transition-transform duration-300 transform hover:scale-105 hover:ring'
            >
              <h3 className='  text-white text-sm md:text-2xl py-4 font-semibold bg-gray-900 rounded-t-2xl'>
                <span className=' p-3 md:px-8 md:py-5 '>
                  {(question as string).charAt(0).toUpperCase() +
                    (question as string).slice(1).toLowerCase()}
                </span>
              </h3>
              <Doughnut
                ref={pieChartRef}
                className='w-full sm:w-full mr-5 sm:-mr-[70px] px-20 md:px-0 py-16 md:py-0'
                data={{
                  labels: Object.keys(data),
                  datasets: [
                    {
                      data: Object.values(data),
                      backgroundColor: adjustedChartColors,
                    },
                  ],
                }}
                options={{
                  elements: {
                    arc: {
                      borderWidth: 2,
                      borderColor: 'white',
                      hoverOffset: 10,
                      hoverBorderWidth: 10,
                      hoverBorderColor: 'white',
                    },
                  },
                  radius: isMobile ? 100 : 135,
                  aspectRatio: isMobile ? 1.2 : 1.6,
                  responsive: true,
                  plugins: {
                    legend: {
                      position: isMobile ? 'bottom' : 'right',
                      align: 'center',
                      labels: {
                        usePointStyle: true,
                        padding: isMobile ? 10 : 24,
                        font: {
                          weight: 'bold',
                          size: 16,
                        },
                        generateLabels: (chart) => {
                          const {data} = chart
                          if (data.labels.length && data.datasets.length) {
                            return data.labels.map((label, index) => {
                              const value = data.datasets[0].data[index]
                              const sentenceCaseLabel =
                                (label as string).charAt(0).toUpperCase() +
                                (label as string).slice(1).toLowerCase()

                              return {
                                text: `${sentenceCaseLabel} - ${value} `,
                                fillStyle: data.datasets[0].backgroundColor[index],
                                hidden: false,
                                index: index,
                              }
                            })
                          }
                          return []
                        },
                      },
                    },
                  },
                }}
              />
              <img
                src='/media/svg/shapes/pie.svg'
                alt='Group-1'
                className=' absolute left-6 size-8 -translate-y-12'
              />
            </div>
          </div>
        )
      }
    })
  }

  const print = () => {
    window.print()
  }

  return (
    <>
      <div className='flex mx-28 justify-between items-center'>
        <h1 className='text-2xl self-center'>Your Graph</h1>
        <button className='btn btn-primary' onClick={print}>
          Download
        </button>
      </div>
      <div id='main-container ' ref={printContainerRef}>
        <div
          id='pdf-container'
          className='md:py-4 grid grid-cols-1 grid-wrap gap-6 md:px-24 md:text-xl text-sm'
        >
          {charts()}
        </div>
        <div
          id='pdf-container1'
          className='md:py-4 my-4 grid sm:grid-cols-2 grid-cols-1 grid-wrap gap-6 md:px-24 md:text-xl text-sm'
        >
          {PieCharts()}
        </div>
      </div>
    </>
  )
}

export default InterviewGraph
