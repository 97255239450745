import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ReportTable from './components/ReportTable'
import ReportDataChecklist from './components/ReportDataChecklist'

function ChecklistReport() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<ReportTable />} />
        <Route path='/:id/*' element={<ReportDataChecklist />} />
      </Routes>
    </div>
  )
}

export default ChecklistReport
