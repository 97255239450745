import React, {useEffect, useState} from 'react'
import {getProject, postGeofencing} from '../_requests'
import {useNavigate} from 'react-router-dom'
import {Circle, GoogleMap, Marker} from '@react-google-maps/api'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store'
import { setProjects } from '../../../redux/project/projectSlice'

const GeofencingForm: React.FC = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const dispatch = useDispatch()
  const projectList = useSelector((state: RootState) => state.project.projects)
  const loading = useSelector((state: RootState) => state.project.loading)
  const [surveyorList, setSurveyorList] = useState(0)
  const [surveyorData, setSurveyorData] = useState([])
  const [projectData, setProjectData] = useState({
    project: '',
    surveyors: [],
  })
  const [coordinates, setCoordinates] = useState({
    lat: 25.276987,
    lng: 55.296249,
  })
  const [radius, setRadius] = useState('500')

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  }

  useEffect(() => {
    const getlist = async () => {
      const res = await getProject()
      dispatch(setProjects(res))
      const data = res.map((item, index) => {
        return item.agent
      })
      setSurveyorData(data)
    }
    if (loading) getlist()
    else {
      const data = projectList.map((item, index) => {
        return item.agent
      })
      setSurveyorData(data)
    }
  }, [])

  const handleMapClick = (event) => {
    setCoordinates({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = {
      geoFencingName: name,
      ...projectData,
      lat: coordinates.lat,
      lng: coordinates.lng,
      radius: Number(radius),
    }
    console.log(data)
    const res = await postGeofencing(data)
    if (res) {
      navigate('/geofencing')
    }
  }

  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Header */}
      <form onSubmit={handleSubmit}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Create New Geo Fencing</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='row gx-10 mb-5'>
            {/* begin::Col */}
            <div className='col-lg-12'>
              <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Name</label>
              <input
                type='text'
                className='form-control form-control-solid '
                name='name'
                placeholder='Name'
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />

              {/* Input group */}
            </div>
            {/* Col */}
            {/* begin::Col */}
          </div>
          <div className='row gx-10 mb-5'>
            {/* begin::Col */}
            <div className='col-lg-12'>
              <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Project Name</label>
              {projectList.map((data, index) => {
                return (
                  <div className='d-flex fv-row my-1' key={data._id}>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        type='radio'
                        name='project_name'
                        value={data._id}
                        id={`projectCheck${data._id}`}
                        className='form-check-input me-3'
                        onChange={() => {
                          setSurveyorList(index)
                          setProjectData((prev) => ({...prev, project: data._id}))
                        }}
                      />
                      <label htmlFor={`projectCheck${data._id}`} className='form-check-label'>
                        {data.name}
                      </label>
                    </div>
                  </div>
                )
              })}
              <label className='form-label fs-6 fw-bold text-gray-700 my-3'>Surveyors</label>
              {surveyorData[surveyorList]?.map((data, index) => {
                return (
                  <div className='d-flex fv-row my-1' key={index}>
                    <input
                      type='checkbox'
                      name='project_surveyor'
                      id={`surveyorCheck${data._id}`}
                      className='form-check-input me-3'
                      value={data.fullName}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setProjectData((prev) => ({
                            ...prev,
                            surveyors: [...prev.surveyors, e.target.value],
                          }))
                        } else {
                          setProjectData((prev) => ({
                            ...prev,
                            surveyors: prev.surveyors.filter((item) => item !== e.target.value),
                          }))
                        }
                      }}
                    />
                    <label htmlFor={`surveyorCheck${data._id}`} className='form-check-label'>
                      {data.fullName}
                    </label>
                  </div>
                )
              })}

              <div className=''>
                <label htmlFor='radius' className='form-label fs-6 fw-bold text-gray-700 my-3'>
                  Geo Fence Radius (in meters)
                </label>
                <input
                  type='number'
                  value={radius}
                  id='radius'
                  min={'0'}
                  className='form-control form-control-solid'
                  onChange={(e) => setRadius(e.target.value)}
                />
              </div>
            </div>

            <div className='card-body py-3'>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: 25.276987,
                    lng: 55.296249,
                  }}
                  zoom={10}
                  onClick={handleMapClick}
                >
                  {/* You can add markers, polylines, etc. here if needed */}
                  <Marker position={coordinates} />
                  <Circle
                    center={coordinates}
                    radius={Number(radius)}
                    // onLoad={onCircleLoad}
                    options={{strokeColor: '#00FFFF'}}
                  />
                </GoogleMap>
            </div>
            {/* Col */}
            {/* begin::Col */}
          </div>
        </div>
        <div className='p-8 pt-0'>
          <button className='btn btn-primary' type='submit'>
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default GeofencingForm
