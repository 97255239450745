import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
const PROJECT_URL = `${API_URL}/project`
const COORDINATE_URL = `${API_URL}/coordinate`


export async function getProject(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PROJECT_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postCoordinate(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${COORDINATE_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postDuplicateCoordinate(id: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${COORDINATE_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getCoordinate(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${COORDINATE_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getCoordinatebyid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${COORDINATE_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function updateCoordinate(data: any, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${COORDINATE_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteCoordinate(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${COORDINATE_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

