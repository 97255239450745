import {createSlice} from '@reduxjs/toolkit'

const initialSlice = {
  coordinates: [],
  loading: true,
}

const coordinateSlice = createSlice({
  name: 'coordinate',
  initialState: initialSlice,
  reducers: {
    setCoordinates(state, action) {
      state.coordinates = action.payload
      state.loading = false
    },
  },
})

export const {setCoordinates} = coordinateSlice.actions
export default coordinateSlice.reducer
