import {Route, Routes} from 'react-router-dom'
import ReportTable from './components/ReportTable'
import ReportData from './components/ReportData'
import ChecklistReport from './ChecklistReport'
import ParkingReport from './ParkingReport'
import CoordinateReport from './components/CoordinateReport'
import GeoFencingReportPage from './components/GeoFencingReportPage'
import JourneyTimeReportPage from './components/JourneyTimeReportPage'
import ReportList from './components/ReportList'
import EditInterviewQuestionaire from '../Interview/components/EditInterviewQuestionaire'

function Report() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<ReportList />} />
        <Route path='/reportList' element={<ReportTable />} />

        <Route path='/checklist/*' element={<ChecklistReport />} />
        <Route path='/parking/*' element={<ParkingReport />} />
        <Route path='/geofencing/*' element={<GeoFencingReportPage />} />
        <Route path='/journey/*' element={<JourneyTimeReportPage />} />
        <Route path='/coordinate/*' element={<CoordinateReport />} />

        {/* only for interview response */}
        <Route path='/:id/edit/:answerId' element={<EditInterviewQuestionaire />} />
        <Route path='/:id/*' element={<ReportData />} />
      </Routes>
    </div>
  )
}

export default Report
