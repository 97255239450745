import TextInput from './FormElements/TextInput'
import {useEffect, useState} from 'react'
import MultipleChoiceInput from './FormElements/MultipleChoiceInput'
import CheckBoxesInput from './FormElements/CheckBoxesInput'
import DropdownInput from './FormElements/DropdownInput'
import ToggleButton from './FormElements/ToggleButton'
import {KTIcon} from '../../../../_metronic/helpers'
import ToggleButton2 from './FormElements/ToggleButton2'
import {getProject, postInterview} from '../_requets'
import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setProjects} from '../../../redux/project/projectSlice'
import {addInterview} from '../../../redux/interview/interviewSlice'

const InterviewFormBuilder = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const projectList = useSelector((state: RootState) => state.project.projects)
  const loading = useSelector((state: RootState) => state.project.loading)
  const [error, setError] = useState(false)
  const [formData, setFormData] = useState([
    {
      question: '',
      type: 'text',
      required: false,
      other: false,
      options: [''],
    },
  ])

  const [groupOption, setGroupOption] = useState<any>()
  const [selectedGroup, setSelectedGroup] = useState<any>()
  const [surveyorList, setSurveyorList] = useState(0)
  const [surveyorData, setSurveyorData] = useState([])
  const [validateInput, setValidateInput] = useState(false)
  const [interviewData, setInterviewData] = useState({
    interviewName: '',
    project: '',
    surveyors: [],
  })

  useEffect(() => {
    const getlist = async () => {
      const res = await getProject()
      dispatch(setProjects(res))
      const data = res.map((item, index) => {
        return item.agent
      })
      setSurveyorData(data)
    }
    if (loading) getlist()
    else {
      const data = projectList.map((item, index) => {
        return item.agent
      })
      setSurveyorData(data)
    }
  }, [])
  console.log('i am selected ', selectedGroup)
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(false)
    if (
      interviewData.interviewName.length < 3 ||
      interviewData.project.length < 1 ||
      interviewData.surveyors.length < 1
    ) {
      setValidateInput(true)
      return
    }
    setValidateInput(true)
    let formValid = true
    formData.forEach((item) => {
      if (item.question.length < 3) {
        formValid = false
      }
      if (item.type !== 'text') {
        item.options.forEach((elem) => {
          if (elem.length < 1) {
            formValid = false
          }
        })
      }
    })
    if (formValid) {
      const data = {
        questions: formData,
        ...interviewData,
        group: selectedGroup,
      }
      if (!error) {
        console.log(data)
        const res = await postInterview(data)
        console.log(res)
        dispatch(addInterview(res))
        navigate('/interview')
      }
    } else {
      setError(true)
    }
  }

  const handleRequiredButton = (index: number) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index] = {
        ...updatedFormData[index],
        required: !updatedFormData[index].required,
      }
      return updatedFormData
    })
  }

  const handleOtherInputButton = (index: number) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index] = {
        ...updatedFormData[index],
        other: !updatedFormData[index].other,
      }
      return updatedFormData
    })
  }

  const handleAddQuestion = () => {
    setFormData((prev) => [
      ...prev,
      {
        question: '',
        type: 'text',
        required: false,
        other: false,
        options: [''],
      },
    ])
  }

  const updateOptions = (index: number, optionIndex: number, newOption: string) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index] = {
        ...updatedFormData[index],
        options: updatedFormData[index].options.map((item, i) => {
          if (i === optionIndex) {
            return newOption
          }
          return item
        }),
      }
      return updatedFormData
    })
  }

  const AddNewOption = (index: number) => {
    setFormData((prev) => {
      const updatedForm = prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            options: [...item.options, ''],
          }
        }
        return item
      })
      return updatedForm
    })
  }

  const deleteOption = (index: number, optionIndex: number) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index] = {
        ...updatedFormData[index],
        options: updatedFormData[index].options.filter((_, i) => i !== optionIndex),
      }
      return updatedFormData
    })
  }
  console.log('i am ', groupOption)
  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <h3 className='mb-6 text-lg font-semibold'>Create New Interview</h3>
        <div className='w-full rounded-lg bg-white p-4'>
          <form id='kt_modal_add_user_form' className='form' noValidate onSubmit={handleFormSubmit}>
            <h3 className='mb-6 text-base font-semibold'>Interview Name</h3>
            <input
              type='text'
              name='question'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              value={interviewData.interviewName}
              onChange={(e) => {
                setInterviewData((prev) => ({...prev, interviewName: e.target.value}))
              }}
            />
            <h3 className='my-6 text-base font-semibold'>Project Name</h3>
            {projectList.map((data, index) => {
              return (
                <div className='d-flex fv-row my-1' key={data._id}>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      type='radio'
                      name='project_name'
                      value={data._id}
                      id={`projectCheck${data._id}`}
                      className='form-check-input me-3'
                      onChange={() => {
                        setGroupOption(data?.group?.map((item) => item))
                        setSurveyorList(index)
                        setInterviewData((prev) => ({...prev, project: data._id}))
                      }}
                    />
                    <label htmlFor={`projectCheck${data._id}`} className='form-check-label'>
                      {data.name}
                    </label>
                  </div>
                </div>
              )
            })}
            <h3 className='my-6 text-base font-semibold'>Group</h3>
            {groupOption && (
              <>
                <div className='my-1'>
                  {groupOption.map((option, index) => (
                    <div className='form-check form-check-custom form-check-solid mb-2' key={index}>
                      <input
                        type='radio'
                        name='group_option'
                        value={option}
                        className='form-check-input me-1'
                        onChange={(e) => setSelectedGroup(e.target.value)}
                      />
                      <label className='form-check-label'>{option}</label>
                    </div>
                  ))}
                </div>
              </>
            )}

            <h3 className='my-6 text-base font-semibold'>Surveyors</h3>
            {surveyorData[surveyorList]?.map((data, index) => {
              return (
                <div className='d-flex fv-row my-1' key={index}>
                  <input
                    type='checkbox'
                    name='project_surveyor'
                    id={`surveyorCheck${data._id}`}
                    className='form-check-input me-3'
                    value={data.fullName}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setInterviewData((prev) => ({
                          ...prev,
                          surveyors: [...prev.surveyors, e.target.value],
                        }))
                      } else {
                        setInterviewData((prev) => ({
                          ...prev,
                          surveyors: prev.surveyors.filter((item) => item !== e.target.value),
                        }))
                      }
                    }}
                  />
                  <label htmlFor={`surveyorCheck${data._id}`} className='form-check-label'>
                    {data.fullName}
                  </label>
                </div>
              )
            })}
            <div className=''>
              {formData.map((data, index) => {
                return (
                  <div key={index}>
                    <h3 className='my-6 text-base font-semibold'>Interview Questions</h3>
                    <div className='flex w-full items-center'>
                      <input
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0 flex-grow' // Add flex-grow to expand the input
                        placeholder='What is your question?'
                        value={data.question}
                        onChange={(e) => {
                          setFormData((prev) => {
                            const updatedFormData = prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  question: e.target.value,
                                }
                              }
                              return item
                            })
                            return updatedFormData
                          })
                        }}
                      />
                      <button
                        className='rounded-lg bg-red-300 p-2 md:ml-4'
                        type='button'
                        onClick={() =>
                          setFormData((prev) => prev.filter((_, idx) => idx !== index))
                        }
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </button>
                    </div>
                    <div className='mx-4 my-6'>
                      <h4 className='mb-4 text-base font-semibold'>Field Type</h4>
                      <select
                        name=''
                        id=''
                        className='form-select form-select-sm form-select-solid'
                        ata-control='select2'
                        data-placeholder='Latest'
                        data-hide-search='true'
                        onChange={(e) => {
                          setFormData((prev) => {
                            const updatedFormData = prev.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  type: e.target.value,
                                }
                              }
                              return item
                            })
                            return updatedFormData
                          })
                        }}
                      >
                        <option value='text'>Text</option>
                        <option value='checkbox'>Checkboxes</option>
                        <option value='multiple'>Multiple Choice</option>
                        <option value='dropdown'>Dropdown</option>
                      </select>
                    </div>
                    <div className=''>
                      {data.type === 'text' && <TextInput {...data} />}
                      {data.type === 'checkbox' && (
                        <CheckBoxesInput
                          options={data.options}
                          updateOptions={(newOption, optionIndex) =>
                            updateOptions(index, optionIndex, newOption)
                          }
                          AddNewOption={() => AddNewOption(index)}
                          deleteOption={(optionIndex) => deleteOption(index, optionIndex)}
                        />
                      )}
                      {data.type === 'multiple' && (
                        <MultipleChoiceInput
                          options={data.options}
                          updateOptions={(newOption, optionIndex) =>
                            updateOptions(index, optionIndex, newOption)
                          }
                          AddNewOption={() => AddNewOption(index)}
                          deleteOption={(optionIndex) => deleteOption(index, optionIndex)}
                        />
                      )}
                      {data.type === 'dropdown' && (
                        <DropdownInput
                          options={data.options}
                          updateOptions={(newOption, optionIndex) =>
                            updateOptions(index, optionIndex, newOption)
                          }
                          AddNewOption={() => AddNewOption(index)}
                          deleteOption={(optionIndex) => deleteOption(index, optionIndex)}
                        />
                      )}
                    </div>
                    <div className='flex gap-4'>
                      <ToggleButton
                        handleRequiredButton={() => handleRequiredButton(index)}
                        index={index}
                      />
                      {data.type !== 'text' && (
                        <ToggleButton2
                          handleOtherInputButton={() => handleOtherInputButton(index)}
                          index={index}
                        />
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='mx-auto my-6 flex items-center justify-center'>
              <button type='button' className='btn btn-secondary' onClick={handleAddQuestion}>
                Add Question
              </button>
            </div>
            {error && (
              <div className='alert alert-danger' role='alert'>
                Input options cannot be empty!
              </div>
            )}
            {validateInput && (
              <>
                {interviewData.interviewName.length < 3 && (
                  <div className='alert alert-danger' role='alert'>
                    Input cannot be less than 3!
                  </div>
                )}
                {interviewData.project.length < 1 && (
                  <div className='alert alert-danger' role='alert'>
                    Select project name!
                  </div>
                )}
                {interviewData.surveyors.length < 1 && (
                  <div className='alert alert-danger' role='alert'>
                    Select atleast one surveyor!
                  </div>
                )}
              </>
            )}
            <div className='mx-auto my-6 md:ml-8'>
              <button className='btn btn-primary' type='submit'>
                Create Interview
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default InterviewFormBuilder
