import React, {useEffect, useState} from 'react'
import {getResponseFromParkingId} from '../_requests'
import {Bar} from 'react-chartjs-2'

const ParkingReportGraph: React.FC = () => {
  const [parkingData, setParkingData] = useState<Array<any>>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = window.location.pathname
        const parts = url.split('/')
        const res = await getResponseFromParkingId(parts[3])
        setParkingData(res)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])
  function formatTime(beforeTime) {
    // Check if beforeTime is in "09:00 AM" format
    if (/^\d{1,2}:\d{2}\s(?:AM|PM)$/.test(beforeTime)) {
      // If it's already in "09:00 AM" format, return the same value
      return beforeTime
    } else {
      // Otherwise, assume it's in ISO format "2024-03-28T08:30:00.000Z"
      // Convert ISO format to local time
      const date = new Date(beforeTime)
      // Format the date to desired format (12-hour format)
      return date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: true})
    }
  }
  const renderBuildingInfo = () => {
    if (parkingData.length === 0) return null

    const {parkingId} = parkingData[0] // Assuming parkingId is same for all data

    return (
      <div className='flex flex-col justify-center items-center'>
        <div className='grid grid-cols-2 text-base px-5 sm:text-xl text-start font-semibold text-gray-700 place-items-center grid-wrap sm:w-2/3 gap-3'>
          <div>
            <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
              Parking Name - {parkingId.parkingName}
            </div>
            <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
              Zone - {parkingId.zone}
            </div>
            <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
              Floor - {parkingId.floor}
            </div>
            <div className='border shadow-sm rounded-xl px-3 py-2 my-3'>
              <p>
                Peak Hours :{' '}
                {parkingId?.peakHours && (
                  <>
                    {Object.keys(parkingId.peakHours).map((period, index) => (
                      <span key={period}>
                        {index > 0 && ', '}
                        {period}
                      </span>
                    ))}
                  </>
                )}
              </p>
            </div>
          </div>
          <div className=''>
            <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
              Building Name - {parkingId.buildingName}
            </div>
            <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
              Group - {parkingId.group}
            </div>
            <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
              Supply - {parkingId.supply}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderCharts = () => {
    const isMobile = window.innerWidth <= 768
    let combinedLabels: string[] = []
    let combinedDataValues: number[] = []

    const allCharts = parkingData.map((data) => {
      const {parkingId, surveys} = data
      const uniquePeakHours = [...(new Set(surveys.map((survey: any) => survey.peakHour)) as any)]

      return uniquePeakHours.map((peakHour: any) => {
        const filteredSurveys = surveys.filter((survey: any) => survey.peakHour === peakHour)
        const labels = filteredSurveys.map(
          (survey: any) => `${formatTime(survey.beforeTime)} - ${formatTime(survey.timeInterval)}`
        )
        const dataValues = filteredSurveys.map(
          (survey: any) => (survey.parked * 100) / parkingId.supply
        )

        combinedLabels = [...combinedLabels, ...labels]
        combinedDataValues = [...combinedDataValues, ...dataValues]

        const chartData = {
          labels: labels,
          datasets: [
            {
              label: 'Occupied',
              data: dataValues,
              barThickness: isMobile ? 20 : 45,
              backgroundColor: '#17C653', // You can customize color here
            },
          ],
        }

        return (
          <div
            className='flex flex-col justify-center items-center'
            key={`${parkingId}-${peakHour}`}
          >
            {/* Render other information here */}
            {/* <div className='grid grid-cols-2 text-base px-5 sm:text-xl text-start font-semibold text-gray-700 place-items-center grid-wrap sm:w-2/3 gap-3'>
              <div>
                <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
                  Parking Name - {parkingId.parkingName}
                </div>
                <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
                  Zone - {parkingId.zone}
                </div>
                <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
                  Floor - {parkingId.floor}
                </div>
                <div className='border shadow-sm rounded-xl px-3 py-2 my-3'>
                  <p>
                    Peak Hours :{' '}
                    {parkingId?.peakHours && (
                      <>
                        {Object.keys(parkingId.peakHours).map((period, index) => (
                          <span key={period}>
                            {index > 0 && ', '}
                            {period}
                          </span>
                        ))}
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className=''>
                <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
                  Building Name - {parkingId.buildingName}
                </div>
                <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
                  Group - {parkingId.group}
                </div>
                <div className=' border  shadow-sm rounded-xl px-3 py-2 my-3'>
                  Supply - {parkingId.supply}
                </div>
              </div>
            </div> */}
            <div className=' w-[90%] border shadow rounded-xl my-8 '>
              <h2 className='text-start mx-14 mt-5 text-2xl font-semibold text-gray-800 '>
                Parking Occupancy
              </h2>
              <h4 className='text-start mx-14 my-1  text-lg font-semibold text-gray-500'>
                Statistics By Interval - {peakHour}
              </h4>
              <Bar
                className='w-[85%] md:mx-auto md:my-8 px-10 py-2 my-5 md:px-0 md:py-0'
                data={chartData}
                options={{
                  indexAxis: isMobile ? 'y' : 'x',
                  aspectRatio: isMobile ? 1 : 2,
                  elements: {
                    bar: {
                      borderRadius: 14,
                      base: 3,
                      inflateAmount: 1,
                      backgroundColor: '#17C653',
                      borderWidth: 1,
                    },
                  },
                  plugins: {
                    legend: {
                      position: 'bottom',
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const value = isMobile
                            ? context.parsed.x.toFixed(1)
                            : context.parsed.y.toFixed(1) // Format to two decimal places
                          return `Occupied : ${value}%`
                        },
                      },
                    },
                  },
                  responsive: true,
                  scales: {
                    x: {
                      title: {
                        display: false,
                        text: 'Time',
                      },
                      border: {
                        display: true,
                        color: '#fff',
                        dashOffset: 30,
                        dash: [7, 7],
                      },
                      grid: {
                        display: isMobile ? true : false,
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'Occupied(%)',
                      },
                      border: {
                        display: true,
                        color: '#fff',
                        dashOffset: 30,
                        dash: [7, 7],
                      },
                      grid: {
                        display: isMobile ? false : true,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        )
      })
    })

    // Add combined data chart
    const combinedChartData = {
      labels: combinedLabels,
      datasets: [
        {
          label: 'Occupied (Combined)',
          data: combinedDataValues,
          backgroundColor: '#17C653', // You can customize color here
        },
      ],
    }

    const combinedChart = (
      <div className='flex flex-col justify-center items-center' key='combined-chart'>
        <div className=' w-[90%] border shadow rounded-xl my-8 '>
          <h2 className='text-start mx-14 mt-5 text-2xl font-semibold text-gray-800 '>
            Combined Parking Occupancy
          </h2>
          <h4 className='text-start mx-14 my-1  text-lg font-semibold text-gray-500'>
            Statistics By All Interval
          </h4>
          <Bar
            className='w-[85%] md:mx-auto md:my-8 px-10 py-2 my-5 md:px-0 md:py-0'
            data={combinedChartData}
            options={{
              indexAxis: isMobile ? 'y' : 'x',
              aspectRatio: isMobile ? 1 : 2,
              elements: {
                bar: {
                  borderRadius: 14,
                  base: 3,
                  inflateAmount: 1,
                  backgroundColor: '#17C653',
                  borderWidth: 1,
                },
              },
              plugins: {
                legend: {
                  position: 'bottom',
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const value = isMobile
                        ? context.parsed.x.toFixed(1)
                        : context.parsed.y.toFixed(1) // Format to two decimal places
                      return `Occupied : ${value}%`
                    },
                  },
                },
              },
              responsive: true,
              scales: {
                x: {
                  title: {
                    display: false,
                    text: 'Time',
                  },
                  border: {
                    display: true,
                    color: '#fff',
                    dashOffset: 30,
                    dash: [7, 7],
                  },
                  grid: {
                    display: isMobile ? true : false,
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'Occupied(%)',
                  },
                  border: {
                    display: true,
                    color: '#fff',
                    dashOffset: 30,
                    dash: [7, 7],
                  },
                  grid: {
                    display: isMobile ? false : true,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    )

    return [combinedChart, ...allCharts]
  }

  return (
    <>
      {renderBuildingInfo()}
      {renderCharts()}
    </>
  )
}

export default ParkingReportGraph
