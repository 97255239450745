import React, {useEffect, useState} from 'react'
import {getResponsesFromChecklistId} from '../_ChecklistRequest'
import {Pie} from 'react-chartjs-2'

const ReportGraphChecklist: React.FC = () => {
  const [answers, setAnswers] = useState([])
  const [chartData, setChartData] = useState({})

  useEffect(() => {
    const url = window.location.pathname
    const parts = url.split('/')
    const fetchData = async () => {
      const res = await getResponsesFromChecklistId(parts[3])
      setAnswers(res[0].answers)
    }
    fetchData()
  }, [])

  useEffect(() => {
    const getReports = () => {
      const dataForChart = {}
      answers.forEach((answer) => {
        answer.questions.forEach((question) => {
          if (question.type === 'text') return
          if (!dataForChart[question.question]) {
            dataForChart[question.question] = {}
            question.response.forEach((response) => {
              if (!dataForChart[question.question][response]) {
                dataForChart[question.question][response] = 1
              } else {
                dataForChart[question.question][response] += 1
              }
            })
          } else {
            question.response.forEach((response) => {
              if (!dataForChart[question.question][response]) {
                dataForChart[question.question][response] = 1
              } else {
                dataForChart[question.question][response] += 1
              }
            })
          }
        })
      })
      setChartData(dataForChart)
    }

    getReports()
  }, [answers])

  const renderPieCharts = () => {
    return Object.entries(chartData).map(([question, data]) => (
      <div key={question}>
        <h3>{question}</h3>
        <Pie
          data={{
            labels: Object.keys(data),
            datasets: [
              {
                data: Object.values(data),
                backgroundColor: [
                  'rgba(255, 99, 132, 0.8)',
                  'rgba(54, 162, 235, 0.8)',
                  'rgba(255, 206, 86, 0.8)',
                  'rgba(75, 192, 192, 0.8)',
                  'rgba(153, 102, 255, 0.8)',
                ],
              },
            ],
          }}
          options={{
            maintainAspectRatio: true,
            aspectRatio: 3,
          }}
          width={20}
          height={20}
        />
      </div>
    ))
  }

  return (
    <>
      <h1 className='justify-center text-center'>Your Graph</h1>
      {renderPieCharts()}
    </>
  )
}

export default ReportGraphChecklist
