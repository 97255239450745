import {createSlice} from '@reduxjs/toolkit'

const initialSlice = {
  interviewData: [],
  loading: true,
}

const interviewReportSlice = createSlice({
  name: 'interviewReport',
  initialState: initialSlice,
  reducers: {
    setInterviewReport(state, action) {
      state.interviewData = action.payload
      state.loading = false
    },
    deleteInterviewReport(state, action) {
      state.interviewData = state.interviewData.filter((item) => item._id !== action.payload)
    },
  },
})

export const {setInterviewReport, deleteInterviewReport} = interviewReportSlice.actions
export default interviewReportSlice.reducer
