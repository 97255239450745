import React, {useEffect, useState} from 'react'
import {GoogleMap, Marker} from '@react-google-maps/api'
import {getGeoCoordinates} from '../_requests'
import {getParkingbyid} from '../../Parking/_requests'

const containerStyle = {
  width: '100%',
  height: '500px',
}

const GeoMapParking: React.FC = () => {
  const [center, setCenter] = useState({
    lat: 25.276987,
    lng: 55.296249,
  })
  const [coordinates, setCoordinates] = useState({
    lat: 25.276987,
    lng: 55.296249,
  })
  useEffect(() => {
    const url = window.location.pathname
    const parts = url.split('/')
    const fetchData = async () => {
      const res = await getParkingbyid(parts[3])
      //   console.log(res)
      setCoordinates({lat: Number(res.lat), lng: Number(res.lng)})
      setCenter({lat: Number(res.lat), lng: Number(res.lng)})
    }
    fetchData()
  }, [])

  return (
    <div className='my-4'>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
          {/* You can add markers, polylines, etc. here if needed */}
          <Marker position={coordinates} label='C1' />
        </GoogleMap>
    </div>
  )
}

export default GeoMapParking
