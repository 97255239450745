import React from 'react'

interface ToggleButtonProps {
  index: number
  handleOtherInputButton: () => void
}

const ToggleButton2: React.FC<ToggleButtonProps> = ({index, handleOtherInputButton}) => {
  const handleToggle = () => {
    handleOtherInputButton()
  }

  return (
    <div className='flex items-center mt-2'>
      <div className='form-check form-switch form-check-custom form-check-solid'>
        <input
          className='form-check-input h-20px w-30px'
          type='checkbox'
          value=''
          id={`switchCheck2${index}`}
          onClick={handleToggle}
        />
        <label className='form-check-label' htmlFor={`switchCheck2${index}`}>
          add "Other"
        </label>
      </div>
    </div>
  )
}

export default ToggleButton2
