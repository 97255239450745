import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Dashboard from '../Pages/Dashboard/Dashboard'
import Checklist from '../Pages/Checklist/Checklist'
import Coordinate from '../Pages/Coordinate/Coordinate'
import Geofencing from '../Pages/Geofencing/Geofencing'
import Interview from '../Pages/Interview/Interview'
import Report from '../Pages/Report/Report'
import TractLiveData from '../Pages/Geofencing/components/TractLiveData'
import Parking from '../Pages/Parking/Parking'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const Journeytime = lazy(() => import('../Pages/Journeytime/Journeytime'))

  return (
    <>
      <TractLiveData />
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboards' />} />

          <Route path='dashboards/*' element={<Dashboard />} />
          {currentUser?.profileType === 'admin' && <Route path='/reports/*' element={<Report />} />}

          <Route path='/interview/*' element={<Interview />} />
          <Route path='/checklist/*' element={<Checklist />} />
          <Route path='/parking/*' element={<Parking />} />
          <Route path='geofencing/*' element={<Geofencing />} />
          <Route
            path='journeytime/*'
            element={
              <SuspensedView>
                <Journeytime />
              </SuspensedView>
            }
          />
          <Route path='coordinate/*' element={<Coordinate />} />
          {/* Pages */}

          {/* Lazy Modules */}
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path='user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
