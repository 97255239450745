import {createSlice} from '@reduxjs/toolkit'

const initialSlice = {
  checklistData: [],
  loading: true,
}

const checklistReportSlice = createSlice({
  name: 'checklistReport',
  initialState: initialSlice,
  reducers: {
    setChecklistData(state, action) {
      state.checklistData = action.payload
      state.loading = false
    },
  },
})

export const {setChecklistData} = checklistReportSlice.actions
export default checklistReportSlice.reducer
