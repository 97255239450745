import axios, {Axios, AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_AGENT_URL = `${API_URL}/agent/actives`
const PROJECT_URL = `${API_URL}/project`
const RESPONSE_URL = `${API_URL}/response`
const PARKING_RESPONSE_URL = `${API_URL}/parkingResponse`
const COORDINATE_URL = `${API_URL}/coordinate`
const GEOFENCING_URL = `${API_URL}/geofencing`
const GEOFENCELOGS_URL = `${API_URL}/geofencinglogs`
const JOURNEYTIME_URL = `${API_URL}/journeytime`
const CHECKLIST_RESPONSE_URL = `${API_URL}/ChecklistResponse`

export async function getAgent(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GET_AGENT_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postProject(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${PROJECT_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getProject(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PROJECT_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}
export async function getInterviewByProjectGroup(id,group): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RESPONSE_URL}/byGroup/${id}?group=${group}`);
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}
export async function getInterviewCountByGroup(id): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${RESPONSE_URL}/responseCount/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getProjectbyid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PROJECT_URL}/id/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getProjectbyId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PROJECT_URL}/id/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function updateProject(data: any, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${PROJECT_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteProject(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${PROJECT_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

// API functions for Tasks report

export async function getResponseInterviewByProjectId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${API_URL}/interview/project/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getResponseInterviewByProjectIdForSurveyor(id: string, surveyorId: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${API_URL}/interview/project/${id}/${surveyorId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getResponseChecklistByProjectId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${CHECKLIST_RESPONSE_URL}/project/${id}/responses`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getResponseParkingByProjectId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${PARKING_RESPONSE_URL}/project/${id}/responses`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getGeofencingByProjectId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${GEOFENCING_URL}/project/${id}/responses`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getJTByProjectId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${JOURNEYTIME_URL}/project/${id}/responses`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getCoordinateByProjectId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${COORDINATE_URL}/project/${id}/responses`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}
