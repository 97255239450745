import React from 'react'

interface TextInputProps {
  question: string
  type: string
  options: string[]
}

const TextInput: React.FC<TextInputProps> = (data) => {
  return (
    <input
      type='text'
      className='form-control form-control-solid mb-3 mb-lg-0'
      placeholder={data.question}
      disabled
      autoComplete='off'
    />
  )
}

export default TextInput
