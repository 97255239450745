import React, {useEffect, useState} from 'react'
import {getResponsesFromChecklistId} from '../_ChecklistRequest'
import * as XLSX from 'xlsx'
import { useAuth } from '../../../modules/auth'

const ChecklistReportPage: React.FC = () => {
  const [checklistName, setChecklistName] = useState('')
  const [answers, setAnswers] = useState([])
  const {currentUser} = useAuth()

  useEffect(() => {
    const url = window.location.pathname
    const parts = url.split('/')

    const fetchData = async () => {
      const res = await getResponsesFromChecklistId(parts[3])
      console.log('Response:', res)
      console.log(res[0])
      setChecklistName(res[0].checklistId.CheckListName)

      setAnswers(res[0].answers)
      console.log('answers:', answers)
    }
    fetchData()
  }, [])

  const formatDate = (date: Date) => {
    const date1 = new Date(date)
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }).format(date1)
    return formattedDate
  }

  const handleDownload = () => {
    const jsonToExcel = (data) => {
      const dataForExcel = data.map((item) => ({
        _id: item._id,
        ...item.questions.reduce((acc, curr) => {
          acc[curr.question] = curr.response.join(', ')
          return acc
        }, {}),
        ...item.geoCoordinates,
      }))

      const worksheet = XLSX.utils.json_to_sheet(dataForExcel)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})

      // Convert Excel data to Blob
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
      const fileName = 'data.xlsx'

      // Create download link
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.setAttribute('download', fileName)

      // Trigger download
      downloadLink.click()
    }

    jsonToExcel(answers)
  }

  return (
    <section>
      <div className={`card card-xl-stretch mb-xl-8`}>
        <div className='card-header border-0 pt-5 '>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 capitalize'>{checklistName}</span>
          </h3>
          {currentUser?.profileType === 'admin' && <div className=''>
            <button className='btn btn-primary' onClick={handleDownload}>
              Download Data
            </button>
          </div>}
        </div>
        <div className='card-body py-3'>
          {answers.map((item, index) => {
            return (
              <div className='row gx-10 mb-5 card p-3' key={index}>
                <div className='col-lg-12'>
                  <div className='text-muted flex justify-between'>
                    <p>#{index + 1}</p>
                    <p>{formatDate(item.createdAt)}</p>
                  </div>
                  <div className='font-semibold'>
                    {item.questions.map((elem, idx) => {
                      return (
                        <div className='my-2' key={idx}>
                          <p className=''>
                            {idx + 1}) {elem.question}
                          </p>
                          {elem.response.map((currElem, i) => {
                            return (
                              <span className='text-primary' key={i}>
                                {currElem}{' '}
                              </span>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                  <div className='text-muted flex justify-end'>
                    <p>
                      lat: {item.geoCoordinates.lat}, lng: {item.geoCoordinates.lng}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ChecklistReportPage
