import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {getAgent, getProjectbyid, postProject, updateProject} from '../Core/_requets'
import {useNavigate, useParams} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import { editProject } from '../../../redux/project/projectSlice'

function ProjectEdit() {
  const dispatch = useDispatch()
  const [agentsList, setAgentsList] = useState<any>([])
  const [selectedAgents, setSelectedAgents] = useState(null)
  const [selectedTasks, setSelectedTasks] = useState(null)
  const [selectedAgentValues, setSelectedAgentValues] = useState([])
  const [selectedTasksValues, setSelectedTasksValues] = useState([])
  const [name, setName] = useState('')
  const [groupField, setGroupField] = useState('')
  const [groups, setGroups] = useState([])

  const [description, setDescription] = useState('')
  const id = useParams().id
  const navigate = useNavigate()

  const getAgenttList = async () => {
    try {
      const res = await getAgent()
      setAgentsList(
        res.map((r) => ({
          value: r._id,
          label: r.fullName, // Corrected the typo here from "labe" to "label"
        }))
      )

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    getAgenttList()
  }, [])

  const getproject = async () => {
    const res = await getProjectbyid(id)
    setSelectedAgentValues(res.agent)
    setSelectedTasksValues(res.tasks)
    setSelectedAgents(
      res.agent.map((r) => ({
        value: r._id,
        label: r.fullName,
      }))
    )

    setSelectedTasks(
      res.tasks.map((r) => ({
        value: r,
        label: r,
      }))
    )
    setName(res.name)
    setDescription(res.description)
    setGroups(res?.group?.map((group) => group))
    console.log(res)
  }

  useEffect(() => {
    getproject()
  }, [])

  const options = [
    {value: 'interview', label: 'Interview'},
    {value: 'geofencing', label: 'Geo Fencing'},
    {value: 'journeytime', label: 'Journey Time'},
    {value: 'checklist', label: 'Check List'},
    {value: 'coordinate', label: 'Co-ordinate'},
    {value: 'parking', label: 'Parking Occupancy'},
  ]

  const handleAgentSelection = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value)
    setSelectedAgents(selectedOptions)
    setSelectedAgentValues(selectedValues)
  }
  const handleTasksSelection = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value)
    setSelectedTasks(selectedOptions)
    setSelectedTasksValues(selectedValues)
  }

  const handleSubmit = async () => {
    const data = {
      name,
      description,
      tasks: selectedTasksValues,
      agent: selectedAgentValues,
      group: groups,
    }

    const res = await updateProject(data, id)
    if (res) {
      dispatch(editProject(res))
      navigate('/dashboards/table')
    }
  }
  const handleAddGroup = () => {
    if (groupField.trim() !== '') {
      setGroups([...groups, groupField.trim()])
      setGroupField('')
    }
  }
  const handleRemoveGroup = (indexToRemove) => {
    setGroups(groups.filter((_, index) => index !== indexToRemove))
  }
  console.log(groups)

  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Add Project</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='row gx-10 mb-5'>
          {/* begin::Col */}
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-solid '
              name='name'
              placeholder='Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            {/* Input group */}
          </div>
          {/* Col */}
          {/* begin::Col */}
        </div>
        <div className='row gx-10 mb-5'>
          {/* begin::Col */}
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Description</label>
            {/* begin::Input group */}
            <input
              type='text'
              className='form-control form-control-solid '
              name='description'
              placeholder='Description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {/* Col */}
        </div>
        {/* Col */}
        {/* begin::Col */}

        <div className='row gx-10 mb-5'>
          {/* begin::Col */}
          <div className='col-lg-9'>
            {' '}
            {/* Adjusted column width */}
            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Groups</label>
            {/* begin::Input group */}
            <div className='input-group'>
              <input
                type='text'
                className='form-control form-control-solid '
                name='groups'
                placeholder='Write group name'
                value={groupField}
                onChange={(e) => setGroupField(e.target.value)}
              />
              {/* Add space between input and button */}
              <button className='btn btn-primary btn-sm' onClick={handleAddGroup}>
                Add Group
              </button>
            </div>
          </div>
          {/* Col */}
        </div>
        <div className='row gx-10 mb-5'>
          {groups.map((group, index) => (
            <div key={index} className='col-lg-3 mb-4'>
              {/* Adjust the column width as per your requirement */}
              <div className='card h-100'>
                <div className='card-body d-flex flex-column justify-content-center'>
                  <h5 className='card-title text-center'>
                    Group {index + 1} : {group}
                    <button
                      className='btn btn-link btn-sm position-absolute top-0 end-0'
                      onClick={() => handleRemoveGroup(index)}
                      style={{zIndex: 1}} // Ensure button is clickable above card
                    >
                      <KTIcon iconName='cross-square' className='text-danger fs-5' />
                    </button>
                  </h5>
                  {/* Add any additional content related to the group */}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className='row gx-10 mb-5'>
          {/* begin::Col */}
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Tasks</label>
            {/* begin::Input group */}
            <Select
              name='tasks'
              aria-label='Select a Tasks'
              placeholder='Select a Tasks'
              options={options}
              isMulti={true}
              value={selectedTasks}
              onChange={handleTasksSelection}
              //   onChange={handleItemChange}
              //   value={selectedItem}
            />
          </div>
          {/* Col */}
          {/* begin::Col */}
        </div>

        <div className='row gx-10 mb-5'>
          {/* begin::Col */}
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Surveyors</label>
            {/* begin::Input group */}
            <Select
              name='surveyors'
              aria-label='Select a Surveyors'
              placeholder='Select a Surveyors'
              options={agentsList}
              isMulti={true}
              value={selectedAgents}
              onChange={handleAgentSelection}
              //   onChange={handleItemChange}
              //   value={selectedItem}
            />
          </div>
          {/* Col */}
          {/* begin::Col */}
        </div>
        <button className='btn btn-primary' onClick={handleSubmit}>
          Edit
        </button>
      </div>
    </div>
  )
}

export default ProjectEdit
