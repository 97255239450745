import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import Select from 'react-select'
import {
  deleteCoordinate,
  deleteGeofencing,
  deleteJT,
  deleteParkingResponses,
  deleteResponses,
  getCoordinate,
  getGeofencing,
  getInterview,
  getJT,
  getParking,
  getProject,
  getResponseInterview,
  getResponseInterviewWithProject,
  getResponseParking,
} from '../_requests'
import {deleteChecklistResponse, getChecklist, getResponseChecklist} from '../_ChecklistRequest'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setProjects} from '../../../redux/project/projectSlice'
import {deleteInterviewReport, setInterviewReport} from '../../../redux/report/interviewReport'
import {setInterviewData} from '../../../redux/interview/interviewSlice'
import {setGeofencingData} from '../../../redux/geofencing/geofencingSlice'
import {setJourneyTimeData} from '../../../redux/journeyTime/journeyTimeSlice'
import {setChecklistData} from '../../../redux/report/checklistReportSlice'
import {setCoordinates} from '../../../redux/coordinate/coordinateSlice'
import {setParkingData} from '../../../redux/report/parkingReportSlice'
import {setChecklist} from '../../../redux/checklist/checklistSlice'
import {setParking} from '../../../redux/parking/parkingSlice'

const ReportTable: React.FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const projectList = useSelector((state: RootState) => state.project.projects)
  const interviewDataList = useSelector(
    (state: RootState) => state.report.interviewReport.interviewData
  )
  const interview = useSelector((state: RootState) => state.interview.interviewData)
  const checklistData = useSelector(
    (state: RootState) => state.report.checklistReport.checklistData
  )
  const parkingDataList = useSelector((state: RootState) => state.report.parkingReport.parkingData)
  const geofencingData = useSelector((state: RootState) => state.geofencing.geofencingData)
  const journeyData = useSelector((state: RootState) => state.journeyTime.journeyTimeData)
  const coordinateData = useSelector((state: RootState) => state.coordinate.coordinates)
  const searchParams = new URLSearchParams(location.search)
  const value = searchParams.get('value')
  const [tableData, setTableData] = useState([])
  const [parkingTableData, setParkingTableData] = useState([])
  const [ChecklistTableData, setChecklistTableData] = useState([])
  const [coordinateTableData, setCoordinateTableData] = useState([])
  const [geofencingTableData, setGeofencingTableData] = useState([])
  const [journeyTableData, setJourneyTableData] = useState([])
  const checklist = useSelector((state: RootState) => state.checkList.checklistData)
  const parking = useSelector((state: RootState) => state.parking.parkingData)
  const [deleteid, setdeleteid] = useState<string>('')
  const [deleteType, setDeleteType] = useState('response')
  // const [selectedTasks, setSelectedTasks] = useState({value: value, label: value})

  const fetchData = async () => {
    if (value) {
      if (projectList.length === 0) {
        const projectResponse = await getProject()
        dispatch(setProjects(projectResponse))
      }
      switch (value) {
        case 'interview':
          if (interviewDataList.length === 0) {
            const interviewResponse = await getResponseInterviewWithProject()
            setTableData(interviewResponse)
            dispatch(setInterviewReport(interviewResponse))
          } else {
            setTableData(interviewDataList)
          }
          // if (interview.length === 0) {
          //   const interviewData = await getInterview()
          //   dispatch(setInterviewData(interviewData))
          // }
          break
        case 'geofencing':
          if (geofencingData.length === 0) {
            const geofencingResponse = await getGeofencing()
            setGeofencingTableData(geofencingResponse)
            dispatch(setGeofencingData(geofencingResponse))
          } else {
            setGeofencingTableData(geofencingData)
          }
          break
        case 'journeytime':
          if (journeyData.length === 0) {
            const journeyResponse = await getJT()
            setJourneyTableData(journeyResponse)
            dispatch(setJourneyTimeData(journeyResponse))
          } else {
            setJourneyTableData(journeyData)
          }
          break
        case 'checklist':
          if (checklistData.length === 0) {
            const checklistResponse = await getResponseChecklist()
            setChecklistTableData(checklistResponse)
            dispatch(setChecklistData(checklistResponse))
          } else {
            setChecklistTableData(checklistData)
          }
          if (checklist.length === 0) {
            const checklistsResponse = await getChecklist()
            dispatch(setChecklist(checklistsResponse))
          }
          break
        case 'coordinate':
          if (coordinateData.length === 0) {
            const coordinateResponse = await getCoordinate()
            setCoordinateTableData(coordinateResponse)
            dispatch(setCoordinates(coordinateResponse))
          } else {
            setCoordinateTableData(coordinateData)
          }
          break
        case 'parking':
          if (parkingDataList.length === 0) {
            const parkingResponse = await getResponseParking()
            setParkingTableData(parkingResponse)
            dispatch(setParkingData(parkingResponse))
          } else {
            setParkingTableData(parkingDataList)
          }
          if (parking.length === 0) {
            const parkingData = await getParking()
            dispatch(setParking(parkingData))
          }
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [value])

  useEffect(() => {
    setTableData(interviewDataList)
  }, [interviewDataList])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const handleDelete = async () => {
    let res
    if (deleteType === 'response') {
      res = await deleteResponses(deleteid)
      console.log(res)
      if (res) {
        dispatch(deleteInterviewReport(deleteid))
      }
    } else if (deleteType === 'parking') {
      res = await deleteParkingResponses(deleteid)
    } else if (deleteType === 'coordinate') {
      res = await deleteCoordinate(deleteid)
    } else if (deleteType === 'geofencing') {
      res = await deleteGeofencing(deleteid)
    } else if (deleteType === 'journey') {
      res = await deleteJT(deleteid)
    }
    fetchData()
    if (res !== null) {
      const closeModalButton = document.getElementById('closeModalButtondelete')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }

  const handleDeleteChecklist = async () => {
    const res = await deleteChecklistResponse(deleteid)
    fetchData()
    if (res !== null) {
      const closeModalButton = document.getElementById('closeModalButtondeleteChecklist')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }
  return (
    <>
      {(value === 'interview' || value === 'all') && (
        <div className='card card-xl-stretch mb-xl-8'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Interview</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='min-w-50px ps-4 rounded-start'>No.</th>
                    <th className='min-w-300px'>Name</th>
                    <th className='min-w-200px'>Project</th>
                    <th className='min-w-100px'>Created On</th>
                    <th className='text-end rounded-end'></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data, index) => {
                    return (
                      <tr key={data._id}>
                        <td>
                          <p className='font-semibold ps-4'>{index + 1}</p>
                        </td>
                        <td>
                          <Link
                            to={`/reports/${data?.interviewId?._id}`}
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {/* {
                              interview.filter((item, i) => {
                                return item._id === data.interviewId
                              })[0]?.interviewName
                            } */}
                            {data?.interviewId?.interviewName}
                          </Link>
                        </td>
                        <td>
                          <p className='font-semibold'>
                            {/* {
                              projectList.filter((item, i) => {
                                return (
                                  item._id ===
                                  interview.filter((item2, i) => {
                                    return item2._id === data.interviewId
                                  })[0]?.project
                                )
                              })[0]?.name
                            } */}
                            {data?.interviewId?.project?.[0]?.name}
                          </p>
                        </td>
                        <td>
                          <p className='font-semibold'>{formatCreatedAt(data?.createdAt)}</p>
                        </td>
                        <td className='text-end'>
                          <div
                            className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_interview'
                            onClick={() => {
                              setdeleteid(data?._id)
                              setDeleteType('response')
                            }}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
          <div className='modal fade' tabIndex={-1} id='kt_modal_interview'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Delete</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>

                <div className='modal-body'>
                  <h3> Are you sure you want to delete</h3>
                </div>

                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                    id='closeModalButtondelete'
                  >
                    No
                  </button>
                  <button type='button' className='btn btn-primary' onClick={handleDelete}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {(value === 'checklist' || value === 'all') && (
        <div className='card card-xl-stretch mb-xl-8 mt-4'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>CheckList</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='min-w-50px ps-4 rounded-start'>No.</th>
                    <th className='min-w-300px'>Name</th>
                    <th className='min-w-200px'>Project</th>
                    <th className='min-w-100px'>Created On</th>
                    <th className='text-end rounded-end'></th>
                  </tr>
                </thead>
                <tbody>
                  {ChecklistTableData.map((data, index) => {
                    return (
                      <tr key={data._id}>
                        <td>
                          <p className='font-semibold ps-4'>{index + 1}</p>
                        </td>
                        <td>
                          <Link
                            to={`/reports/checklist/${data.checklistId}`}
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {
                              checklist.filter((item, i) => {
                                return item._id === data.checklistId
                              })[0]?.CheckListName
                            }
                          </Link>
                        </td>
                        <td>
                          <p className='font-semibold'>
                            {
                              projectList.filter((item, i) => {
                                return (
                                  item._id ===
                                  checklist.filter((item2, i) => {
                                    return item2._id === data.checklistId
                                  })[0]?.project
                                )
                              })[0]?.name
                            }
                          </p>
                        </td>
                        <td>
                          <p className='font-semibold'>{formatCreatedAt(data.createdAt)}</p>
                        </td>
                        <td className='text-end'>
                          <div
                            className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_checklist'
                            onClick={() => setdeleteid(data._id)}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
          <div className='modal fade' tabIndex={-1} id='kt_modal_checklist'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Delete</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>

                <div className='modal-body'>
                  <h3> Are you sure you want to delete</h3>
                </div>

                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                    id='closeModalButtondeleteChecklist'
                  >
                    No
                  </button>
                  <button type='button' className='btn btn-primary' onClick={handleDeleteChecklist}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {(value === 'parking' || value === 'all') && (
        <div className='card card-xl-stretch mb-xl-8'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Parking Occupancy</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='min-w-50px ps-4 rounded-start'>No.</th>
                    <th className='min-w-300px'>Name</th>
                    <th className='min-w-200px'>Project</th>
                    <th className='min-w-100px'>Created On</th>
                    <th className='text-end rounded-end'></th>
                  </tr>
                </thead>
                <tbody>
                  {parkingTableData.map((data, index) => {
                    return (
                      <tr key={data._id}>
                        <td>
                          <p className='font-semibold ps-4'>{index + 1}</p>
                        </td>
                        <td>
                          <Link
                            to={`/reports/parking/${data.parkingId}`}
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                          >
                            {
                              parking.filter((item, i) => {
                                return item._id === data.parkingId
                              })[0]?.parkingName
                            }
                          </Link>
                        </td>
                        <td>
                          <p className='font-semibold'>
                            {
                              projectList.filter((item, i) => {
                                return (
                                  item._id ===
                                  parking.filter((item2, i) => {
                                    return item2._id === data.parkingId
                                  })[0]?.project
                                )
                              })[0]?.name
                            }
                          </p>
                        </td>
                        <td>
                          <p className='font-semibold'>{formatCreatedAt(data.createdAt)}</p>
                        </td>
                        <td className='text-end'>
                          <div
                            className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_interview'
                            onClick={() => {
                              setdeleteid(data._id)
                              setDeleteType('parking')
                            }}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
        </div>
      )}

      {(value === 'geofencing' || value === 'all') && (
        <div className='card card-xl-stretch mb-xl-8'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Geo Fencing</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='min-w-50px ps-4 rounded-start'>No.</th>
                    <th className='min-w-300px'>Name</th>
                    <th className='min-w-200px'>Project</th>
                    <th className='min-w-100px'>Created On</th>
                    <th className='text-end rounded-end'></th>
                  </tr>
                </thead>
                <tbody>
                  {geofencingTableData.map((data, index) => {
                    return (
                      <tr key={data._id}>
                        <td>
                          <p className='font-semibold ps-4'>{index + 1}</p>
                        </td>
                        <td>
                          <Link
                            to={`/reports/geofencing/${data._id}`}
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                          >
                            {data?.geoFencingName}
                          </Link>
                        </td>
                        <td>
                          <p className='font-semibold'>{data?.project?.name}</p>
                        </td>
                        <td>
                          <p className='font-semibold'>{formatCreatedAt(data.createdAt)}</p>
                        </td>
                        <td className='text-end'>
                          <div
                            className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_interview'
                            onClick={() => {
                              setdeleteid(data._id)
                              setDeleteType('geofencing')
                            }}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
        </div>
      )}

      {(value === 'journeytime' || value === 'all') && (
        <div className='card card-xl-stretch mb-xl-8'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Journey Time</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='min-w-50px ps-4 rounded-start'>No.</th>
                    <th className='min-w-300px'>Name</th>
                    <th className='min-w-200px'>Project</th>
                    <th className='min-w-100px'>Created On</th>
                    <th className='text-end rounded-end'></th>
                  </tr>
                </thead>
                <tbody>
                  {journeyTableData.map((data, index) => {
                    return (
                      <tr key={data._id}>
                        <td>
                          <p className='font-semibold ps-4'>{index + 1}</p>
                        </td>
                        <td>
                          <Link
                            to={`/reports/journey/${data._id}`}
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                          >
                            {data.name}
                          </Link>
                        </td>
                        <td>
                          <p className='font-semibold'>{data?.project?.name}</p>
                        </td>
                        <td>
                          <p className='font-semibold'>{formatCreatedAt(data.createdAt)}</p>
                        </td>
                        <td className='text-end'>
                          <div
                            className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_interview'
                            onClick={() => {
                              setdeleteid(data._id)
                              setDeleteType('journey')
                            }}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
        </div>
      )}

      {(value === 'coordinate' || value === 'all') && (
        <div className='card card-xl-stretch mb-xl-8'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Co-ordinate</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='min-w-50px ps-4 rounded-start'>No.</th>
                    <th className='min-w-300px'>Name</th>
                    <th className='min-w-200px'>Project</th>
                    <th className='min-w-100px'>Created On</th>
                    <th className='text-end rounded-end'></th>
                  </tr>
                </thead>
                <tbody>
                  {coordinateTableData.map((data, index) => {
                    return (
                      <tr key={data._id}>
                        <td>
                          <p className='font-semibold ps-4'>{index + 1}</p>
                        </td>
                        <td>
                          <Link
                            to={`/reports/coordinate/${data._id}`}
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                          >
                            {data.coordinateName}
                          </Link>
                        </td>
                        <td>
                          <p className='font-semibold'>
                            {
                              // projectList.filter((item, i) => {
                              //   return (
                              //     item._id ===
                              //     parking.filter((item2, i) => {
                              //       return item2._id === data.parkingId
                              //     })[0]?.project
                              //   )
                              // })[0]?.name
                              data.project?.name
                            }
                          </p>
                        </td>
                        <td>
                          <p className='font-semibold'>{formatCreatedAt(data.createdAt)}</p>
                        </td>
                        <td className='text-end'>
                          <div
                            className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_interview'
                            onClick={() => {
                              setdeleteid(data._id)
                              setDeleteType('coordinate')
                            }}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ReportTable
