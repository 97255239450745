import React from 'react'
import {Outlet, Route, Routes, useNavigate, useParams} from 'react-router'
import GeoMap from './GeoMap'
import ReportPage from './ReportPage'
import ReportGraph from './ReportGraph'
import {useAuth} from '../../../modules/auth'

const ReportData: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const {currentUser} = useAuth()

  const handleRedirect = (path: string) => {
    navigate(`/reports/${params.id}/${path}`)
  }
  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <div className='flex gap-4 items-center w-full justify-center'>
          <button className='btn btn-primary' onClick={() => handleRedirect('')}>
            Report
          </button>
          <button className='btn btn-success' onClick={() => handleRedirect('map')}>
            Maps
          </button>
          {currentUser?.profileType === 'admin' && (
            <button className='btn btn-danger' onClick={() => handleRedirect('graph')}>
              Graphs
            </button>
          )}
        </div>
      </div>
      <Outlet />
      <Routes>
        <Route index element={<ReportPage />} />
        <Route path='/map' element={<GeoMap />} />
        <Route path='/graph' element={<ReportGraph />} />
      </Routes>
    </section>
  )
}

export default ReportData
