import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  projects: [],
  loading: true,
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload
      state.loading = false
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    addProject: (state, action) => {
      state.projects.push(action.payload)
    },
    deleteProject: (state, action) => {
      state.projects = state.projects.filter((project) => project._id !== action.payload)
    },
    editProject: (state, action) => {
      state.projects = state.projects.map((project) => {
        if (project._id === action.payload._id) {
          return action.payload
        }
        return project
      })
    },
  },
})

export const {setProjects, setLoading, addProject, deleteProject, editProject} =
  projectSlice.actions
export default projectSlice.reducer
