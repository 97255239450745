import React from 'react'
import {Outlet, Route, Routes, useNavigate, useParams} from 'react-router'
import GeoMapChecklist from './GeoMapChecklist'
import ChecklistReportPage from './ChecklistReportPage'
import ReportGraphChecklist from './ReportGraphChecklist'
import { useAuth } from '../../../modules/auth'

const ReportDataChecklist: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const {currentUser} = useAuth()

  const handleRedirect = (path: string) => {
    navigate(`/reports/checklist/${params.id}/${path}`)
  }

  return (
    <section>
      <div className='flex flex-col px-8 py-6'>
        <div className='flex gap-4 items-center w-full justify-center'>
          <button className='btn btn-primary' onClick={() => handleRedirect('')}>
            Report
          </button>
          <button className='btn btn-success' onClick={() => handleRedirect('map')}>
            Maps
          </button>
          {currentUser?.profileType === 'admin' && <button className='btn btn-danger' onClick={() => handleRedirect('graph')}>
            Graphs
          </button>}
        </div>
      </div>
      <Outlet />
      <Routes>
        <Route index element={<ChecklistReportPage />} />
        <Route path='/map' element={<GeoMapChecklist />} />
        <Route path='/graph' element={<ReportGraphChecklist />} />
      </Routes>
    </section>
  )
}

export default ReportDataChecklist
