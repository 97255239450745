import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useLocation} from 'react-router'
import { useEffect, useState } from 'react'


export function MenuInner() {

  const { pathname } = useLocation();
  const [currentUrl, setCurrentUrl] = useState<string>('');

  useEffect(() => {
    // Check if the URL contains the substring "marketing"
    if (pathname.includes("marketing")) {
      setCurrentUrl("marketing");
    } else if (pathname.includes("telecom")) {
      setCurrentUrl("telecom");
    }else if (pathname.includes("hrm")) {
      setCurrentUrl("hrm");
    }else if (pathname.includes("property")) {
      setCurrentUrl("property");
    }else if (pathname.includes("events")) {
      setCurrentUrl("events");
    }else if (pathname.includes("sales")) {
      setCurrentUrl("sales");
    } else {
      // If none of the conditions match, set a default value
      setCurrentUrl("none");
    }
  }, [pathname]);

  useEffect(() => {
    // Log the updated currentUrl
    console.log(currentUrl);
  }, [pathname]);

  return (
    <>
    {
      currentUrl === "marketing" ? (
        <>
        <MenuItem title="marketing1" to='/marketing/marketing1' />
      <MenuItem title='marketing2' to='/marketing/marketing2' />
      <MenuItem title='marketing3' to='/marketing/marketing3' />

      </>
      

      ): currentUrl === "telecom"? (
        <>
        <MenuItem title="Dashboard" to='/telecom/dashboard' />
      <MenuItem title='Calls' to='/telecom/calls'/>
      <MenuItem title='Failed Calls' to='/telecom/failedcalls' />
      <MenuItem title='Live' to='/telecom/live'/>
      <MenuItem title='Phonebook' to='/telecom/phonebook' />
      <MenuItem title='Dialer' to='/telecom/dialer' />

      </>
      ):currentUrl === "hrm"? (
        <>
         <MenuItem title='HRM1' to='/hrm/hrm1' />
      <MenuItem title='HRM2' to='/hrm/hrm2' />
      <MenuItem title='HRM3' to='/hrm/hrm3' />
      </>
      ):currentUrl === "property"? (
        <>
              <MenuItem title='Primary' to='/property/Primary' />
            <MenuItem title='Secondary' to='/property/secondary' />
      <MenuItem title='Developer' to='/property/developer' />
      <MenuItem title='Maps' to='/property/maps' />
      </>
      ):currentUrl === "events"? (
        <>
              <MenuItem title='Road Show' to='/events/roadshow' />
            <MenuItem title='Launch' to='/events/launch' />
      
      </>
    
      ):currentUrl === "sales"? (
        <>
            <MenuItem title='Deals' to='/sales/deals' />
            <MenuItem title='Quotes' to='/sales/quotes' />
            <MenuItem title='Invoices' to='/sales/invoices' />
      </>
      ):(
        <>

      
      </>
      )
    }
      
    </>
  )
}
