import React, {useEffect, useState} from 'react'
import {getCoordinatebyid} from '../_requests'
import {useParams} from 'react-router-dom'
import {GoogleMap, InfoWindow, Marker} from '@react-google-maps/api'

const CoordinateDetails: React.FC = () => {
  const params = useParams()
  const [coordinateData, setCoordinateData] = useState({
    coordinateName: '',
    project: '',
  })
  const [center, setCenter] = useState({
    lat: 25.276987,
    lng: 55.296249,
  })
  const [coordinates, setCoordinates] = useState([])
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [markerLabel, setMarkerLabel] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getCoordinatebyid(params.id)
      setCenter({
        lat: res.coordinates[0].lat,
        lng: res.coordinates[0].lng,
      })
      console.log(res)
      setCoordinateData({
        coordinateName: res.coordinateName,
        project: res.project.name,
      })
      setCoordinates(
        res.coordinates.map((item) => {
          return {
            lat: item.lat,
            lng: item.lng,
          }
        })
      )
      setMarkerLabel(res.coordinates.map((item) => item.label))
    }
    fetchData()

    var lat, lng
    if ('geolocation' in navigator) {
      // Get the user's current position
      navigator.geolocation.getCurrentPosition(async function (position) {
        // Retrieve latitude and longitude
        lat = position.coords.latitude
        lng = position.coords.longitude
        setCenter({lat, lng})
      })
    }
  }, [])

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  }

  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Coordinates</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='row gx-10 mb-5'>
          {/* begin::Col */}
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-solid '
              name='name'
              placeholder='Name'
              value={coordinateData.coordinateName}
              disabled
            />

            {/* Input group */}
          </div>
          {/* Col */}
          {/* begin::Col */}
        </div>

        <div className='row gx-10 mb-5'>
          {/* begin::Col */}
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Project</label>
            <input
              type='text'
              className='form-control form-control-solid '
              name='name'
              placeholder='Name'
              value={coordinateData.project}
              disabled
            />
          </div>
          {/* Col */}
          {/* begin::Col */}
        </div>

        <div className='card-body py-4'>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={14}
            onClick={(e) => {
              setSelectedMarker(null)
            }}
          >
            {/* You can add markers, polylines, etc. here if needed */}
            {coordinates.map((item, index) => {
              return (
                <Marker
                  position={item}
                  key={index}
                  onClick={() => setSelectedMarker(index)}
                  label={markerLabel[index]}
                >
                  {selectedMarker === index && (
                    <InfoWindow onCloseClick={() => setSelectedMarker(null)} position={item}>
                      <div>
                        <h3>Marker {index + 1}</h3>
                        <h3>{markerLabel[index]}</h3>
                        <p>Lat: {item.lat}</p>
                        <p>Lng: {item.lng}</p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              )
            })}
          </GoogleMap>
        </div>

        <div className='mb-5 fw-bold fs-3 p-4'>
          {coordinates.map((item, index) => {
            return (
              <p key={index}>
                <span className='text-primary capitalize'>{markerLabel[index]}</span> Lat:{' '}
                <span className='text-primary'>{item.lat}</span> Lng:{' '}
                <span className='text-primary'>{item.lng}</span>
              </p>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CoordinateDetails
