import React, {useEffect, useState} from 'react'
import {getCoordinatebyid} from '../_requests'
import * as XLSX from 'xlsx'
import {GoogleMap, InfoWindow, Marker} from '@react-google-maps/api'
import {useAuth} from '../../../modules/auth'

const CoordinateReport: React.FC = () => {
  const {currentUser} = useAuth()
  const [coordinateName, setCoordinateName] = useState('')
  const [coordinateData, setCoordinateData] = useState([])
  const [coordinateTime, setCoordinateTime] = useState(null)
  const [center, setCenter] = useState({
    lat: 25.276987,
    lng: 55.296249,
  })
  //   const [coordinates, setCoordinates] = useState([])
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  }
  const [selectedMarker, setSelectedMarker] = useState(null)
  useEffect(() => {
    const url = window.location.pathname
    const parts = url.split('/')
    const fetchData = async () => {
      const res = await getCoordinatebyid(parts[3])
      console.log(res)
      setCoordinateName(res.coordinateName)
      setCoordinateData(res.coordinates)
      setCoordinateTime(res.createdAt)
      setCenter({
        lat: res.coordinates[0].lat,
        lng: res.coordinates[0].lng,
      })
    }
    fetchData()
  }, [])

  const formatDate = (date: Date) => {
    const date1 = new Date(date)
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }).format(date1)
    return formattedDate
  }

  const handleDownload = () => {
    const jsonToExcel = (data) => {
      const dataForExcel = data.map((item) => ({
        _id: item._id,
        label: item.label,
        lat: item.lat,
        lng: item.lng,
      }))

      const worksheet = XLSX.utils.json_to_sheet(dataForExcel)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})

      // Convert Excel data to Blob
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
      const fileName = 'data.xlsx'

      // Create download link
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.setAttribute('download', fileName)

      // Trigger download
      downloadLink.click()
    }

    jsonToExcel(coordinateData)
  }
  return (
    <section>
      <div className={`card card-xl-stretch mb-xl-8`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 capitalize'>{coordinateName}</span>
          </h3>
          {currentUser?.profileType === 'admin' && (
            <div className=''>
              <button className='btn btn-primary' onClick={handleDownload}>
                Download Data
              </button>
            </div>
          )}
        </div>
        <div className='card-body py-3'>
          <div className='row gx-10 mb-5 card p-3'>
            <div className='col-lg-12'>
              <div className='text-muted flex justify-between'>
                <p>#1</p>
                <p>{formatDate(coordinateTime)}</p>
              </div>
              <div className='card-body py-4'>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  zoom={14}
                  onClick={(e) => {
                    setSelectedMarker(null)
                  }}
                >
                  {/* You can add markers, polylines, etc. here if needed */}
                  {coordinateData.map((item, index) => {
                    return (
                      <Marker
                        position={item}
                        key={index}
                        onClick={() => setSelectedMarker(index)}
                        label={item.label}
                      >
                        {selectedMarker === index && (
                          <InfoWindow onCloseClick={() => setSelectedMarker(null)} position={item}>
                            <div>
                              <h3>Marker {index + 1}</h3>
                              <h3>{item.label}</h3>
                              <p>Lat: {item.lat}</p>
                              <p>Lng: {item.lng}</p>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    )
                  })}
                </GoogleMap>
              </div>
              <div className='font-semibold my-4'>
                {coordinateData.map((elem, idx) => {
                  return (
                    <p key={idx}>
                      <span className='text-primary capitalize'>{elem.label}</span> Lat:{' '}
                      <span className='text-primary'>{elem.lat}</span> Lng:{' '}
                      <span className='text-primary'>{elem.lng}</span>
                    </p>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CoordinateReport
