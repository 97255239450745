import {Route, Routes} from 'react-router-dom'
import InterviewPage from './components/InterviewPage'
import InterviewFormBuilder from './components/InterviewFormBuilder'
import InterviewQuestionaire from './components/InterviewQuestionaire'
import InterviewEditForm from './components/InterviewEditForm'

function Interview() {
  return (
    <Routes>
      <Route path='/' element={<InterviewPage />} />
      <Route path='/create-interview' element={<InterviewFormBuilder />} />
      <Route path='/:id' element={<InterviewQuestionaire />} />
      <Route path='edit-interview/:id' element={<InterviewEditForm />} />
    </Routes>
  )
}

export default Interview
