import React, {useEffect, useState} from 'react'
import {Circle, GoogleMap} from '@react-google-maps/api'
import {getGeofenceLogsbyid, getGeofencingbyid} from '../_requests'
import {useParams} from 'react-router-dom'

const GeofencingPage: React.FC = () => {
  const [data, setData] = useState({
    geoFencingName: '',
    project: {
      name: '',
    },
  })
  const [coordinates, setCoordinates] = useState({
    lat: 25.276987,
    lng: 55.296249,
  })
  const [tableData, setTableData] = useState([])
  const params = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getGeofencingbyid(params.id)
      setData({
        geoFencingName: resp.geoFencingName,
        project: resp.project,
      })
      setCoordinates({
        lat: resp.lat,
        lng: resp.lng,
      })

      const res = await getGeofenceLogsbyid(params.id)
      if (res.length >= 1) {
        setTableData(res[0]?.logs)
      }

      // const data = {
      //   geoFenceId: '659e94cd172f8a5f1c4d977e',
      //   surveyor: 'Faisal',
      //   lat: 19.1334548,
      //   lng: 72.8600207,
      // }

      // const res = await postGeoFenceLog(data)
      // console.log(res)
    }
    fetchData()
  }, [])

  const formatDate = (date: Date) => {
    const date1 = new Date(date)
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }).format(date1)
    return formattedDate
  }

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  }

  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{data?.geoFencingName}</span>
          </h3>
          <h4 className='my-4 text-base font-medium capitalize'>{data?.project?.name}</h4>
        </div>
      </div>

      <div className='card-body py-3'>
          <GoogleMap mapContainerStyle={mapContainerStyle} center={coordinates} zoom={10}>
            {/* You can add markers, polylines, etc. here if needed */}
            <Circle
              center={coordinates}
              radius={500}
              // onLoad={onCircleLoad}
              options={{strokeColor: '#00FFFF'}}
            />
          </GoogleMap>
      </div>
      <div className='card-body py-3 my-4'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-250px'>Surveyor</th>
                <th className='min-w-250px'>Coordinates</th>
                <th className='min-w-150px'>Date</th>
                <th className='min-w-150px'>Time</th>
                <th className='min-w-150px'>Status</th>
                {/* <th className='min-w-200px text-end rounded-end'></th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {tableData?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <p className='text-dark fw-bold d-block mb-1 fs-6'>{item?.surveyor}</p>
                  </td>
                  <td className='text-dark fw-bold d-block mb-1 fs-6'>
                    Lat: {item?.lat}, Lng: {item?.lng}
                  </td>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>
                      {formatDate(item?.createdAt).substring(0, 10)}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>
                      {formatDate(item?.createdAt).substring(12)}
                    </span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold d-block mb-1 fs-6'>
                      {item?.checkInOut ? 'Check In' : 'Check Out'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export default GeofencingPage
