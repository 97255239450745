import React, {useState, useEffect} from 'react'
import {GoogleMap, Polyline, Marker} from '@react-google-maps/api'
import Select from 'react-select'
import {getProject} from '../../Dashboard/Core/_requets'
import {getJTById} from '../_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store'

interface Location {
  latitude: number | null
  longitude: number | null
}

const JourneyTimeReportPage: React.FC = () => {
  const projectData = useSelector((state: RootState) => state.project.projects)
  const [tracking, setTracking] = useState<boolean>(false)
  const [locations, setLocations] = useState<Location[]>([])
  const [elapsedTime, setElapsedTime] = useState(0)
  const [startTime, setStartTime] = useState(null)
  const [userLocation, setUserLocation] = useState<Location>({latitude: null, longitude: null})
  const [name, setName] = useState('')
  const [projectList, setProjectList] = useState<any>([])
  const [selectedProjects, setSelectedProjects] = useState(null)
  const [totalDistance, setTotalDistance] = useState()
  const Navigate = useNavigate()
  const [trackingStartTime, setTrackingStartTime] = useState<string | null>(null)
  const [trackingEndTime, setTrackingEndTime] = useState<string | null>(null)
  const url = window.location.pathname
  const parts = url.split('/')
  const id = parts[3]

  const getProjectList = async () => {
    try {
      const res = await getProject()
      setProjectList(
        res.map((r) => ({
          value: r._id,
          label: r.name, // Corrected the typo here from "labe" to "label"
        }))
      )

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (projectData.length === 0) getProjectList()
    else {
      setProjectList(
        projectData.map((r) => ({
          value: r._id,
          label: r.name,
        }))
      )
    }
  }, [])

  const handleProjectsSelection = (selectedOptions) => {
    setSelectedProjects(selectedOptions)
  }

  const getproject = async () => {
    const res = await getJTById(id)

    setSelectedProjects({value: res._id, label: res.name})

    setName(res?.name)
    setElapsedTime(parseFloat(res?.time))
    setLocations(res?.locations)
    setTotalDistance(res?.distance)
    setTrackingStartTime(res?.startTime)
    setTrackingEndTime(res?.endTime)
    console.log(res)
  }

  useEffect(() => {
    getproject()
  }, [])

  const formatTime = (timeInSeconds: number): string => {
    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
    const seconds = Math.floor(timeInSeconds % 60)

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  }

  const center =
    locations.length > 0
      ? {lat: locations[0].latitude || 0, lng: locations[0].longitude || 0}
      : {lat: 0, lng: 0}

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'

    const formattedDateTime = new Date(createdAt).toLocaleString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

    return formattedDateTime
  }

  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Journey Time</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='row gx-10 mb-5'>
          {/* begin::Col */}
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-solid '
              name='name'
              placeholder='Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled
            />

            {/* Input group */}
          </div>
          {/* Col */}
          {/* begin::Col */}
        </div>

        <div className='row gx-10 mb-5'>
          {/* begin::Col */}
          <div className='col-lg-12'>
            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Tasks</label>
            {/* begin::Input group */}
            <Select
              name='projects'
              aria-label='Select a Project'
              placeholder='Select a Project'
              options={projectList}
              isDisabled={true}
              value={selectedProjects}
              onChange={handleProjectsSelection}
              //   onChange={handleItemChange}
              //   value={selectedItem}
            />
          </div>
          {/* Col */}
          {/* begin::Col */}
        </div>

        <div className='mb-5 fw-bold fs-3'>
          Journey Time: {formatTime(elapsedTime)} | Total Distance:{' '}
          {parseFloat(totalDistance).toFixed(2)} meters
          <br />
          <span>
            Start Time: {new Date(parseInt(trackingStartTime)).toLocaleTimeString()} | End Time:{' '}
            {new Date(parseInt(trackingEndTime)).toLocaleTimeString()}
          </span>
        </div>

          <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={17}>
            {locations.length > 0 && (
              <>
                {/* Polyline for the route */}
                <Polyline
                  path={locations.map((loc) => ({lat: loc.latitude || 0, lng: loc.longitude || 0}))}
                  options={{
                    strokeColor: '#0000FF', // Change this to the color you prefer
                    strokeOpacity: 1,
                    strokeWeight: 2,
                  }}
                />

                {/* Add Green marker for the first location */}
                <Marker
                  position={{
                    lat: locations[0]?.latitude || 0,
                    lng: locations[0]?.longitude || 0,
                  }}
                  icon={{
                    url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                  }}
                />

                {/* Add Red marker for the last location */}
                <Marker
                  position={{
                    lat: locations[locations.length - 1]?.latitude || 0,
                    lng: locations[locations.length - 1]?.longitude || 0,
                  }}
                  icon={{
                    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                  }}
                />
              </>
            )}
          </GoogleMap>

        {/* <button className='btn btn-primary w-150px mt-5' onClick={handlesubmit}>
          submit
        </button> */}
      </div>
    </div>
  )
}

export default JourneyTimeReportPage
