import {combineReducers} from '@reduxjs/toolkit'

import reportCountReducer from './reportCountSlice'
import interviewReportReducer from './interviewReport'
import checklistReportReducer from './checklistReportSlice'
import parkingReportReducer from './parkingReportSlice'

const reportReducer = combineReducers({
  reportCount: reportCountReducer,
  interviewReport: interviewReportReducer,
  checklistReport: checklistReportReducer,
  parkingReport: parkingReportReducer,
})

export default reportReducer
