import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api'
import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {getInterviewByProjectGroup} from '../Core/_requets'
const containerStyle = {
  width: '100%',
  height: '500px',
}

const center = {
  lat: 25.276987,
  lng: 55.296249,
}
const InterviewMap = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [coordinates, setCoordinates] = useState([])
  const {id} = useParams()
  const value = searchParams.get('value')

  const fetchData = async () => {
    try {
      const res = await getInterviewByProjectGroup(id, value)
      console.log(res)
      const extractedCoordinates = res.flatMap((interview) => {
        return interview.answers.map((answer) => {
          return {
            lat: answer.geoCoordinates.lat,
            lng: answer.geoCoordinates.lng,
          }
        })
      })
      setCoordinates(extractedCoordinates)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  console.log(value, id)
  return (
    <div className='my-4'>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {/* You can add markers, polylines, etc. here if needed */}
        {coordinates.map((item, index) => (
          <Marker position={item} key={index} label={`C${index + 1}`} />
        ))}
      </GoogleMap>
    </div>
  )
}

export default InterviewMap
