import React from 'react'
import {Route, Routes} from 'react-router-dom'
import GeofencingTable from './components/GeofencingTable'
import GeofencingForm from './components/GeofencingForm'
import GeofencingPage from './components/GeofencingPage'

const Geofencing: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<GeofencingTable />} />
      <Route path='/create-interview' element={<GeofencingForm />} />
      <Route path='/:id' element={<GeofencingPage />} />
    </Routes>
  )
}

export default Geofencing
