import {Route, Routes} from 'react-router-dom'
import ParkingPage from './components/ParkingPage'
import ParkingForm from './components/ParkingForm'
import ParkingQuestionaire from './components/ParkingQuestionaire'

function Parking() {
  return (
    <Routes>
      <Route path='/' element={<ParkingPage />} />
      <Route path='/create-parking' element={<ParkingForm />} />
      <Route path='/:id' element={<ParkingQuestionaire />} />
      {/* <Route path='edit-interview/:id' element={<ParkingEditForm />} /> */}
    </Routes>
  )
}

export default Parking
