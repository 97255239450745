import React, {useEffect, useState} from 'react'
import {GoogleMap, Marker} from '@react-google-maps/api'
import {getGeoCoordinates} from '../_requests'

const containerStyle = {
  width: '100%',
  height: '500px',
}

const center = {
  lat: 25.276987,
  lng: 55.296249,
}

const GeoMap: React.FC = () => {
  const [coordinates, setCoordinates] = useState([])
  useEffect(() => {
    const url = window.location.pathname
    const parts = url.split('/')
    const fetchData = async () => {
      const res = await getGeoCoordinates(parts[2])
      // console.log(res[0])
      setCoordinates(res[0])
    }
    fetchData()
  }, [])

  return (
    <div className='my-4'>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {/* You can add markers, polylines, etc. here if needed */}
        {coordinates.map((item, index) => (
          <Marker position={item} key={index} label={`C${index + 1}`} />
        ))}
      </GoogleMap>
    </div>
  )
}

export default GeoMap
