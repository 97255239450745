import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  getCoordinateByProjectId,
  getGeofencingByProjectId,
  getInterviewCountByGroup,
  getJTByProjectId,
  getProjectbyId,
  getResponseChecklistByProjectId,
  getResponseInterviewByProjectId,
  getResponseInterviewByProjectIdForSurveyor,
  getResponseParkingByProjectId,
} from '../Core/_requets'
import {KTSVG} from '../../../../_metronic/helpers'
import { useAuth } from '../../../modules/auth'

const ProjectDetails: React.FC = () => {
  const params = useParams()
  const {currentUser} = useAuth()
  const [project, setProject] = React.useState<any>({
    name: '',
    description: '',
    createdAt: null,
    agent: [],
    group: [],
  })
  const [interviewTableData, setInterviewTableData] = useState([])
  const [checklistTableData, setChecklistTableData] = useState([])
  const [parkingTableData, setParkingTableData] = useState([])
  const [geofencingTableData, setGeofencingTableData] = useState([])
  const [journeyTableData, setJourneyTableData] = useState([])
  const [coordinateTableData, setCoordinateTableData] = useState([])
  const [selectedGroup, setSelectedGroup] = useState<string>('')
  const [groupCount, setGroupCount] = useState<any>()

  useEffect(() => {
    const fetchData = async () => {
      const project = await getProjectbyId(params.id)
      console.log(project)
      setProject(project)

      const count = await getInterviewCountByGroup(params.id)
      setGroupCount(count)

      const interview = await getResponseInterviewByProjectId(params.id)
      setInterviewTableData(interview)

      const checklist = await getResponseChecklistByProjectId(params.id)
      setChecklistTableData(checklist)

      const parking = await getResponseParkingByProjectId(params.id)
      setParkingTableData(parking)

      const geofencing = await getGeofencingByProjectId(params.id)
      setGeofencingTableData(geofencing)

      const journeyTime = await getJTByProjectId(params.id)
      setJourneyTableData(journeyTime)

      const coordinate = await getCoordinateByProjectId(params.id)
      setCoordinateTableData(coordinate)
    }

    const fetchDataForSurveyor = async () => {
      const interview = await getResponseInterviewByProjectIdForSurveyor(params.id, currentUser?.fullName)
      setInterviewTableData(interview)
    }
    if (currentUser?.profileType === 'admin') {
      fetchData()
    } else if (currentUser?.profileType === 'agent') {
      fetchDataForSurveyor()
    }
  }, [currentUser])

  const formatDate = (date: Date) => {
    const date1 = new Date(date)
    let formattedDate = date1.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    return formattedDate
  }
  console.log('ye hai ', interviewTableData)
  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }
  console.log(selectedGroup)
  const filteredInterview = selectedGroup
    ? interviewTableData.filter((item) => item.group === selectedGroup)
    : interviewTableData

  return (
    <section>
      <div className={`card card-xl-stretch mb-xl-8`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 capitalize'>{project.name}</span>
          </h3>
        </div>
        <div className='card-header border-0 pt-2'>
          <h4 className='fw-semibold'>{project.description}</h4>
        </div>
        <div className='card-header border-0 mt-n4'>
          <h4 className='fw-semibold'>Created on: {formatDate(project?.createdAt)}</h4>
        </div>
        <div className='card-header border-0 mt-n4'>
          <h4 className='fw-bold'>
            Group:{' '}
            {project?.group?.map((item, index) => {
              return (
                <span className='badge badge-light-primary fs-7 fw-semibold me-2' key={index}>
                  {item}
                </span>
              )
            })}
          </h4>
        </div>
        <div className='card-header border-0 mt-n4'>
          <h4 className='fw-bold'>
            Surveyors:{' '}
            {project.agent.map((item, index) => {
              return (
                <span className='badge badge-light-warning fs-7 fw-semibold me-2' key={index}>
                  {item.fullName}
                </span>
              )
            })}
          </h4>
        </div>
        <div className='card-header border-0 mt-n4'>
          <h4 className='fw-semibold'>
            Tasks:{' '}
            {project.tasks?.map((item, index) => {
              return (
                <span className='badge badge-light-success fs-7 fw-semibold me-2' key={index}>
                  {item}
                </span>
              )
            })}
          </h4>
        </div>
        {currentUser?.profileType !== 'agent' && <div className='card-body'>
          <div className='card card-xl-stretch mb-xl-8 mt-5'>
            <div className='card-header border-0 pt-3 pb-0'>
              <h3 className='card-title d-flex justify-content-center align-items-center gap-2'>
                <span className='card-label fw-bold fs-4 mb-0'>Group</span>
              </h3>
            </div>

            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='min-w-50px ps-4 rounded-start'>No.</th>
                      <th className='min-w-300px'>Group</th>
                      <th className='min-w-300px'>Response Count</th>
                      <th className='text-end rounded-end'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {project?.group?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <p className='font-semibold ps-4'>{index + 1}</p>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: `/dashboards/details/${params.id}`,
                                search: `?value=${data}`,
                              }}
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            >
                              {data}
                            </Link>
                          </td>

                          <td className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {groupCount ? groupCount[data] : 0}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
            </div>
          </div>
        </div>}
        <div className='card-body'>
          {interviewTableData.length > 0 && (
            <div className='card card-xl-stretch mb-xl-8 mt-5'>
              <div className='card-header border-0 pt-3 pb-0'>
                <h3 className='card-title d-flex justify-content-center align-items-center gap-2'>
                  <span className='card-label fw-bold fs-4 mb-0'>Interview</span>

                  <select
                    className='form-select form-select-solid mb-0'
                    value={selectedGroup}
                    onChange={(e) => setSelectedGroup(e.target.value)}
                  >
                    <option value=''>All</option>
                    {project?.group?.map((group: any) => (
                      <option key={group} value={group}>
                        {group}
                      </option>
                    ))}
                  </select>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted bg-light'>
                        <th className='min-w-50px ps-4 rounded-start'>No.</th>
                        <th className='min-w-300px'>Name</th>
                        <th className='min-w-100px'>Group</th>
                        <th className='min-w-300px'>Project</th>

                        <th className='min-w-100px'>Created On</th>
                        <th className='text-end rounded-end'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredInterview?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <p className='font-semibold ps-4'>{index + 1}</p>
                            </td>
                            <td>
                              <Link
                                to={`/reports/${data?._id}`}
                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                              >
                                {data?.interviewName}
                              </Link>
                            </td>
                            <td>{data?.group}</td>
                            <td>{data?.project?.name}</td>

                            <td>
                              <p className='font-semibold'>{formatCreatedAt(data?.createdAt)}</p>
                            </td>
                            <td className='text-end'>
                              {/* <div
                              className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_interview'
                              onClick={() => {
                                setdeleteid(data._id)
                                setDeleteType('response')
                              }}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </div> */}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
              </div>
              <div className='modal fade' tabIndex={-1} id='kt_modal_interview'>
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>Delete</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>

                    <div className='modal-body'>
                      <h3> Are you sure you want to delete</h3>
                    </div>

                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-light'
                        data-bs-dismiss='modal'
                        id='closeModalButtondelete'
                      >
                        No
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary'
                        // onClick={handleDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {checklistTableData.length > 0 && (
            <div className='card card-xl-stretch mb-xl-8 mt-4'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Checklist</span>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted bg-light'>
                        <th className='min-w-50px ps-4 rounded-start'>No.</th>
                        <th className='min-w-300px'>Name</th>
                        <th className='min-w-100px'>Created On</th>
                        <th className='text-end rounded-end'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {checklistTableData.map((data, index) => {
                        return (
                          <tr key={data[0]._id}>
                            <td>
                              <p className='font-semibold ps-4'>{index + 1}</p>
                            </td>
                            <td>
                              <Link
                                to={`/reports/checklist/${data[0].checklistId?._id}`}
                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                              >
                                {data[0]?.checklistId?.CheckListName}
                              </Link>
                            </td>
                            <td>
                              <p className='font-semibold'>{formatCreatedAt(data[0].createdAt)}</p>
                            </td>
                            <td className='text-end'>
                              {/* <div
                              className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_checklist'
                              onClick={() => setdeleteid(data._id)}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </div> */}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
              </div>
            </div>
          )}
          {parkingTableData.length > 0 && (
            <div className='card card-xl-stretch mb-xl-8 mt-4'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Parking Occupation</span>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted bg-light'>
                        <th className='min-w-50px ps-4 rounded-start'>No.</th>
                        <th className='min-w-300px'>Name</th>
                        <th className='min-w-100px'>Created On</th>
                        <th className='text-end rounded-end'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {parkingTableData.map((data, index) => {
                        return (
                          <tr key={data[0]._id}>
                            <td>
                              <p className='font-semibold ps-4'>{index + 1}</p>
                            </td>
                            <td>
                              <Link
                                to={`/reports/parking/${data[0].parkingId?._id}`}
                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                              >
                                {data[0]?.parkingId?.parkingName}
                              </Link>
                            </td>
                            <td>
                              <p className='font-semibold'>{formatCreatedAt(data[0].createdAt)}</p>
                            </td>
                            <td className='text-end'>
                              {/* <div
                              className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_checklist'
                              onClick={() => setdeleteid(data._id)}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </div> */}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
              </div>
            </div>
          )}
          {geofencingTableData.length > 0 && (
            <div className='card card-xl-stretch mb-xl-8 mt-4'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Geo Fencing</span>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted bg-light'>
                        <th className='min-w-50px ps-4 rounded-start'>No.</th>
                        <th className='min-w-300px'>Name</th>
                        <th className='min-w-100px'>Created On</th>
                        <th className='text-end rounded-end'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {geofencingTableData.map((data, index) => {
                        return (
                          <tr key={data._id}>
                            <td>
                              <p className='font-semibold ps-4'>{index + 1}</p>
                            </td>
                            <td>
                              <Link
                                to={`/reports/geofencing/${data?._id}`}
                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                              >
                                {data?.geoFencingName}
                              </Link>
                            </td>
                            <td>
                              <p className='font-semibold'>{formatCreatedAt(data.createdAt)}</p>
                            </td>
                            <td className='text-end'>
                              {/* <div
                              className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_checklist'
                              onClick={() => setdeleteid(data._id)}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </div> */}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
              </div>
            </div>
          )}
          {journeyTableData.length > 0 && (
            <div className='card card-xl-stretch mb-xl-8 mt-4'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Journey Time</span>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted bg-light'>
                        <th className='min-w-50px ps-4 rounded-start'>No.</th>
                        <th className='min-w-300px'>Name</th>
                        <th className='min-w-100px'>Created On</th>
                        <th className='text-end rounded-end'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {journeyTableData?.map((data, index) => {
                        return (
                          <tr key={data._id}>
                            <td>
                              <p className='font-semibold ps-4'>{index + 1}</p>
                            </td>
                            <td>
                              <Link
                                to={`/reports/journey/${data?._id}`}
                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                              >
                                {data?.name}
                              </Link>
                            </td>
                            <td>
                              <p className='font-semibold'>{formatCreatedAt(data.createdAt)}</p>
                            </td>
                            <td className='text-end'>
                              {/* <div
                              className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_checklist'
                              onClick={() => setdeleteid(data._id)}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </div> */}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
              </div>
            </div>
          )}
          {coordinateTableData.length > 0 && (
            <div className='card card-xl-stretch mb-xl-8 mt-4'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Co-ordinate</span>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted bg-light'>
                        <th className='min-w-50px ps-4 rounded-start'>No.</th>
                        <th className='min-w-300px'>Name</th>
                        <th className='min-w-100px'>Created On</th>
                        <th className='text-end rounded-end'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {coordinateTableData.map((data, index) => {
                        return (
                          <tr key={data?._id}>
                            <td>
                              <p className='font-semibold ps-4'>{index + 1}</p>
                            </td>
                            <td>
                              <Link
                                to={`/reports/coordinate/${data?._id}`}
                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                              >
                                {data?.coordinateName}
                              </Link>
                            </td>
                            <td>
                              <p className='font-semibold'>{formatCreatedAt(data.createdAt)}</p>
                            </td>
                            <td className='text-end'>
                              {/* <div
                              className='cursor-pointer rounded-md bg-gray-200 p-2 flex items-center justify-center'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_checklist'
                              onClick={() => setdeleteid(data._id)}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </div> */}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default ProjectDetails
