import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
// const GET_AGENT_URL = `${API_URL}/agent/actives`
const PROJECT_URL = `${API_URL}/project`
const PARKING_URL = `${API_URL}/parking`
const RESPONSE_URL = `${API_URL}/response`
const PARKING_RESPONSE_URL = `${API_URL}/parkingResponse`

export async function getProject(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PROJECT_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postParking(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${PARKING_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postDuplicateParking(id: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${PARKING_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getParking(): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PARKING_URL}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getParkingById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PARKING_URL}/get/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function updateParking(data: any, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${PARKING_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function deleteParking(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${PARKING_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function postResponse(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(
      `${PARKING_RESPONSE_URL}/${data.parkingId}/parkingResponse`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export async function getParkingbyid(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PARKING_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}
export async function getParkingFirstRecordbyId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
        `${PARKING_RESPONSE_URL}/${id}/firstRecord`,
      );
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}
