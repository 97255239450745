import {createSlice} from '@reduxjs/toolkit'

const initialSlice = {
  countData: {
    interview: 0,
    checklist: 0,
    parking: 0,
    geofencing: 0,
    journeytime: 0,
    coordinate: 0,
  },
  loading: true,
}

const reportCountSlice = createSlice({
  name: 'reportCount',
  initialState: initialSlice,
  reducers: {
    setReportCount(state, action) {
      state.countData = action.payload
      state.loading = false
    },
  },
})

export const {setReportCount} = reportCountSlice.actions
export default reportCountSlice.reducer
