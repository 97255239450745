import React, {useEffect, useState} from 'react'
import {getResponseFromParkingId} from '../_requests'
import * as XLSX from 'xlsx'
import {useAuth} from '../../../modules/auth'

const ParkingReportPage: React.FC = () => {
  const {currentUser} = useAuth()
  const [parkingData, setParkingData] = useState({
    parkingName: '',
    group: '',
    buildingName: '',
    floor: '',
    zone: '',
    supply: 0,
    peakHours: [],
  })
  const [selectedPeakHour, setSelectedPeakHour] = useState('')
  const [filteredResponses, setFilteredResponses] = useState([])

  const [responses, setResponses] = useState([])
  useEffect(() => {
    const url = window.location.pathname
    const parts = url.split('/')
    const fetchData = async () => {
      const res = await getResponseFromParkingId(parts[3])
      console.log(res)
      setParkingData(res[0].parkingId)
      setResponses(res[0].surveys)
      setFilteredResponses(res[0].surveys)
    }
    fetchData()
  }, [])

  const formatDate = (date: Date) => {
    const date1 = new Date(date)
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }).format(date1)
    return formattedDate
  }

  const handleDownload = () => {
    const jsonToExcel = (data) => {
      const dataForParkingExcel = [
        {
          Group: parkingData.group,
          'Name of the Building': parkingData.buildingName,
          'Parking floor': parkingData.floor,
          'Parking zone': parkingData.zone,
          'Parking supply': parkingData.supply,
        },
      ]
      const worksheet1 = XLSX.utils.json_to_sheet(dataForParkingExcel)
      const questions = data.map((item, idx) => {
        return {
          'Parking occupied time before the survey': item.beforeTime,
          'Parking occupied number before the survey': item.beforeOccupied,
          'Parking Occupied time interval': item.timeInterval,
          'Number of cars Parked': item.parked,
          'Parking occupancy percentage': (item.parked * 100) / parkingData.supply,
        }
      })
      const dataForExcel = data.map((item, index) => ({
        _id: item._id,
        ...questions[index],
      }))

      const worksheet = XLSX.utils.json_to_sheet(dataForExcel)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet1, 'Parking')
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})

      // Convert Excel data to Blob
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
      const fileName = 'data.xlsx'

      // Create download link
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.setAttribute('download', fileName)

      // Trigger download
      downloadLink.click()
    }

    jsonToExcel(responses)
  }
  function formatTime(beforeTime) {
    // Check if beforeTime is in "09:00 AM" format
    if (/^\d{1,2}:\d{2}\s(?:AM|PM)$/.test(beforeTime)) {
      // If it's already in "09:00 AM" format, return the same value
      return beforeTime
    } else {
      // Otherwise, assume it's in ISO format "2024-03-28T08:30:00.000Z"
      // Convert ISO format to local time
      const date = new Date(beforeTime)
      // Format the date to desired format (12-hour format)
      return date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: true})
    }
  }
  console.log(selectedPeakHour)
  console.log(responses)
  useEffect(() => {
    if (selectedPeakHour === 'all') {
      setFilteredResponses(responses) // Show all responses
    } else {
      // Filter responses based on selected peak hour
      const filtered = responses.filter((response) => response.peakHour === selectedPeakHour)
      setFilteredResponses(filtered)
    }
  }, [selectedPeakHour])
  return (
    <section>
      <div className={`card card-xl-stretch mb-xl-8`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 capitalize'>
              {parkingData.parkingName}
            </span>
          </h3>
          {currentUser?.profileType === 'admin' && (
            <div className=''>
              <button className='btn btn-primary' onClick={handleDownload}>
                Download Data
              </button>
            </div>
          )}
        </div>
        <div className='col-lg-12 p-8 pt-4'>
          <div className='font-semibold'>
            <div className='my-2'>
              <p className=''>Group</p>
              <span className='text-primary ml-2'>{parkingData.group}</span>
            </div>
            <div className='my-2'>
              <p className=''>Name of the Building</p>
              <span className='text-primary ml-2'>{parkingData.buildingName}</span>
            </div>
            <div className='my-2'>
              <p className=''>Parking floor</p>
              <span className='text-primary ml-2'>{parkingData.floor}</span>
            </div>
            <div className='my-2'>
              <p className=''>Parking zone</p>
              <span className='text-primary ml-2'>{parkingData.zone}</span>
            </div>
            <div className='my-2'>
              <p className=''>Parking supply</p>
              <span className='text-primary ml-2'>{parkingData.supply}</span>
            </div>
            <div className='my-2'>
              <p className=''>Select Peak Hours</p>
              {parkingData?.peakHours && (
                <div>
                  {/* Select dropdown for peak hours */}
                  <select
                    value={selectedPeakHour}
                    onChange={(e) => setSelectedPeakHour(e.target.value)}
                  >
                    <option value='all'>All</option>
                    {Object.keys(parkingData.peakHours).map((period) => (
                      <option key={period} value={period}>
                        {period}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className='card-body py-3'>
          {filteredResponses.map((item, index) => {
            return (
              <div className='row gx-10 mb-5 card p-3' key={index}>
                <div className='col-lg-12'>
                  <div className='text-muted flex justify-between'>
                    <p>#{index + 1}</p>
                    <p>{formatDate(item.createdAt)}</p>
                  </div>
                  <div className='font-semibold'>
                    <div className='my-2'>
                      <p className=''>Parking occupied time before the survey</p>
                      <span className='text-primary'>{formatTime(item.beforeTime)}</span>
                    </div>
                    <div className='my-2'>
                      <p className=''>Parking occupied number before the survey</p>
                      <span className='text-primary'>{item.beforeOccupied}</span>
                    </div>
                    <div className='my-2'>
                      <p className=''>Parking Occupied time interval</p>
                      <span className='text-primary'>{formatTime(item.timeInterval)}</span>
                    </div>
                    <div className='my-2'>
                      <p className=''>Number of cars Parked</p>
                      <span className='text-primary'>{item.parked}</span>
                    </div>
                    <div className='my-2'>
                      <p className=''>Parking occupancy percentage</p>
                      <span className='text-primary'>
                        {(item.parked * 100) / parkingData.supply}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ParkingReportPage
