import {createSlice} from '@reduxjs/toolkit'

const initialSlice = {
  journeyTimeData: [],
  loading: true,
}

const journeyTimeSlice = createSlice({
  name: 'journeyTime',
  initialState: initialSlice,
  reducers: {
    setJourneyTimeData(state, action) {
      state.journeyTimeData = action.payload
      state.loading = false
    },
  },
})

export const {setJourneyTimeData} = journeyTimeSlice.actions
export default journeyTimeSlice.reducer
