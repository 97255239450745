import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {deleteGeofencing, getGeofencing, getProject} from '../_requests'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store'
import { setGeofencingData } from '../../../redux/geofencing/geofencingSlice'
import { Spinner } from 'react-bootstrap'

const GeofencingTable: React.FC = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const tableData = useSelector((state: RootState) => state.geofencing.geofencingData)
  const [deleteid, setdeleteid] = useState<string>('')

  const getlist = async () => {
    const resp = await getGeofencing()
    dispatch(setGeofencingData(resp))
    setLoading(false)
  }

  useEffect(() => {
    if (tableData.length === 0) getlist()
    else setLoading(false)
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const handleDelete = async () => {
    const res = await deleteGeofencing(deleteid)
    getlist()
    if (res !== null) {
      const closeModalButton = document.getElementById('closeModalButtondelete')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }

  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Geo Fencing</span>
        </h3>
        <div className='card-toolbar'>
          <Link to='/geofencing/create-interview' className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            New Geo Fencing
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          {
            loading ? (
              <>
                  <div className='w-full flex items-center justify-center'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                </>
            ) : 
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-100px rounded-start'>No</th>
                <th className='min-w-250px'>Name</th>
                <th className='min-w-250px'>Project</th>
                <th className='min-w-150px'>Created On</th>
                <th className='min-w-200px text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {tableData?.map((item, index) => (
                <tr key={index}>
                  <td className='ps-4'>
                    <div className='d-flex align-items-center'>{index + 1}</div>
                  </td>
                  <td>
                    <Link
                      to={`/geofencing/${item?._id}`}
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {item?.geoFencingName}
                    </Link>
                  </td>
                  <td className='px-2 text-blue-400 capitalize'>{item?.project?.name}</td>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {formatCreatedAt(item?.createdAt)}
                    </span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_2'
                      onClick={() => setdeleteid(item?._id)}
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          }
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <h3> Are you sure you want to delete</h3>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                No
              </button>
              <button type='button' className='btn btn-primary' onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeofencingTable
