import {Route, Routes} from 'react-router-dom'
import ChecklistPage from './components/ChecklistPage'
import ChecklistFormBuilder from './components/ChecklistFormBuilder'
import ChecklistQuestionaire from './components/ChecklistQuestionaire'
import ChecklistEditForm from './components/ChecklistEditForm'

function Interview() {
  return (
    <Routes>
      <Route path='/' element={<ChecklistPage />} />
      <Route path='/create-checklist' element={<ChecklistFormBuilder />} />
      <Route path='/:id' element={<ChecklistQuestionaire />} />
      <Route path='edit-checklist/:id' element={<ChecklistEditForm />} />
    </Routes>
  )
}

export default Interview
