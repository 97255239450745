/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {deleteProject, getProject} from '../Core/_requets'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store'
import { setProjects, deleteProject as removeProjectFromStore } from '../../../redux/project/projectSlice'
// import {KTIcon, toAbsoluteUrl} from '../../../helpers'

const ProjectTable: React.FC = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const projectList = useSelector((state:RootState) => state.project.projects)
  const loading = useSelector((state:RootState) => state.project.loading)
  const [deleteid, setdeleteid] = useState<string>('')

  const getlist = async () => {
    const res = await getProject()
    console.log(res)
    dispatch(setProjects(res))
  }

  useEffect(() => {
    if (loading) getlist()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const handledelete = async () => {
    const res = await deleteProject(deleteid)
    // getlist()
    if (res) {
      dispatch(removeProjectFromStore(deleteid))
      const closeModalButton = document.getElementById('closeModalButtondelete')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }

  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Project</span>
        </h3>
        {currentUser?.profileType === 'admin' && (
          <div className='card-toolbar'>
            <Link to='/dashboards/form' className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              New Project
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>No</th>
                <th className='min-w-150px'>Project Name</th>
                <th className='min-w-150px'>Description</th>
                <th className='min-w-150px'>Groups</th>
                <th className='min-w-250px'>Surveyors</th>
                <th className='min-w-250px'>Tasks</th>
                <th className='min-w-150px'>Created On</th>
                {currentUser?.profileType === 'admin' && (
                  <th className='min-w-150px text-end rounded-end'></th>
                )}{' '}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {projectList.map((item, index) => (
                <tr key={index}>
                  <td className='ps-4'>
                    <div className='d-flex align-items-center'>{index + 1}</div>
                  </td>
                  <td>
                    <Link
                      to={`/dashboards/project/${item._id}`}
                      className='text-dark  text-hover-primary d-block mb-1 fs-6'
                    >
                      {item.name}
                    </Link>
                  </td>
                  <td>
                    <p className='text-dark d-block mb-1 fs-6'>{item.description}</p>
                  </td>
                  <td>
                    {item?.group?.map((res, idx) => (
                      <span className='badge badge-light-primary fs-7 fw-semibold me-2' key={idx}>
                        {res}
                      </span>
                    ))}
                  </td>
                  <td>
                    {item.agent?.map((res, idx) => (
                      <span className='badge badge-light-warning fs-7 fw-semibold me-2' key={idx}>
                        {res.fullName}
                      </span>
                    ))}
                  </td>

                  <td>
                    {item.tasks?.map((res, idx) => (
                      <span className='badge badge-light-success fs-7 fw-semibold me-2' key={idx}>
                        {res}
                      </span>
                    ))}
                  </td>
                  <td>
                    <span className='text-dark d-block mb-1 fs-6'>
                      {formatCreatedAt(item.createdAt)}
                    </span>
                  </td>
                  {currentUser?.profileType === 'admin' && (
                    <td className='text-end'>
                      <Link
                        to={`/dashboards/edit/${item._id}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Link>
                      <Link
                        to='#'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_2'
                        onClick={() => setdeleteid(item._id)}
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </Link>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <h3> Are you sure you want to delete</h3>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                No
              </button>
              <button type='button' className='btn btn-primary' onClick={handledelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ProjectTable}
