import {createSlice} from '@reduxjs/toolkit'

const initialSlice = {
  geofencingData: [],
  loading: true,
}

const geofencingSlice = createSlice({
  name: 'geofencing',
  initialState: initialSlice,
  reducers: {
    setGeofencingData(state, action) {
      state.geofencingData = action.payload
      state.loading = false
    },
  },
})

export const {setGeofencingData} = geofencingSlice.actions
export default geofencingSlice.reducer
