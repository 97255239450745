import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ReportTable from './components/ReportTable'
import ParkingReportData from './components/ParkingReportData'

function ParkingReport() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<ReportTable />} />
        <Route path='/:id/*' element={<ParkingReportData />} />
      </Routes>
    </div>
  )
}

export default ParkingReport
